import React from "react";
import "../App.css";
import Mission from "./mission";
import Vision from "./intro";
import Testimonials from "../Home/testimonial";
import Navbar from "../Home";
import Footer from "../Home/footer";
import Contact from "./contactus";
import Secondary from "../Home/secondary";

const About = () => {
  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <Navbar />
        <Vision />
        <Mission />
        <Testimonials />
        <div className="back">
          <br></br>
          <br></br>
        </div>
        <Secondary />
        <div className="back">
          <br></br>
          <br></br>
        </div>
        <Contact />
        <Footer />
      </div>
    </>
  );
};

export default About;
