import React, { useEffect } from "react";
import "../App.css";
import Cont2 from "./getstarted";
import Container1 from "./container";
import Products from "./products";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../redux-store/feature/product/productSlice";
import Testimonials from "./testimonial";
import Cont3 from "./premium";
import Navbar from "./index";
import Footer from "./footer";
import Use from "./use";
import Secondary from "./secondary";
import PromotionProducts from "./promotion-products";

const Home = () => {
  const products = useSelector((state) => state.product.promotionProducts);
  const dispatch = useDispatch();
  useEffect(() => {
    const listProducts = async () => {
      dispatch(getProducts());
    };
    listProducts();
  }, [dispatch]);
  return (
    <div style={{ overflow: "hidden" }}>
      <Navbar />
      <Container1 />
      <Cont2 />
      <Products />
      {products?.length !== 0 && <PromotionProducts />}
      <Testimonials />
      <div className="back">
        <br></br>
        <br></br>
      </div>
      <Secondary />
      <div className="back2">
        <br></br>
        <br></br>
      </div>
      <Cont3 />
      <Footer />
    </div>
  );
};

export default Home;
