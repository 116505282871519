import React from "react";
import "../App.css";
import "./testi";
import "bootstrap/dist/css/bootstrap.min.css";
import myImage from "../images/female2.jfif";
import img1 from "../images/male1.jfif";
import img2 from "../images/female1.jfif";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Testimonials extends React.Component {
  render() {
    const settings = {
      arrows: true,
      dots: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,

      // eslint-disable-next-line
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2,
            dots: false,
          },
        },
        {
          breakpoint: 650,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            dots: false,
          },
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
          },
        },
      ],
    };
    return (
      <div className="container-fluid start">
        <h2 className="h2 get">
          <b>CLIENT’S EXPERIENCE</b>
        </h2>
        <div className="line "></div>

        <p className="para">
          Testimonials from few of our many satisfied clients.
        </p>

        <div
          className="row"
          style={{ marginRight: "2rem", marginLeft: "2rem" }}
        >
          <Slider {...settings}>
            <div
              className=" testimonial "
              style={{
                marginRight: "2rem",
                marginLeft: "2rem",
                minWidth: "500px",
              }}
            >
              <div className="pic">
                <img src={myImage}></img>
              </div>
              <div className="testimonial-content">
                <p>
                  All I did was visit the grabIT website, and I found the
                  product I needed. Within 2 days, I received my purchased
                  product at the comfort of my home. GrabIT Online marketplace
                  saved me the stress and Time.
                </p>
              </div>
              <h3 className="testimonial-title">
                <a className="tesm" href="#">
                  <b>Kelly Mbah</b>
                </a>
                <small>- Banker</small>
              </h3>
            </div>
            <div
              className=" testimonial "
              style={{ marginRight: "2rem", marginLeft: "2rem" }}
            >
              <div className="pic">
                <img src={img2}></img>
              </div>
              <div className="testimonial-content">
                <p>
                  {" "}
                  With GrabIT, it took me less than 72 hours to find my desired
                  gadget, Order purchase and get it delivered. Awesome Services
                  and highly Recommended, The best online market place to
                  purchase from.
                </p>
              </div>
              <h3 className="testimonial-title">
                <a className="tesm" href="#">
                  <b>Sally Embolo</b>
                </a>
                <small>- Accountant</small>
              </h3>
            </div>

            <div
              className="testimonial"
              style={{ marginRight: "2rem", marginLeft: "2rem" }}
            >
              <div className="pic">
                <img src={img1}></img>
              </div>
              <div className="testimonial-content">
                <p>
                  My new favorite place! If you are looking for products to buy
                  & wish to sell aswell , then you've come to the right place.
                  GrabIT has just about every variety of product, with a range
                  of prizes.
                </p>
              </div>
              <h3 className="testimonial-title">
                <a className="tesm" href="#">
                  <b>Peter Ayuk</b>
                </a>
                <small>- Web Developer</small>
              </h3>
            </div>

            <div
              className=" testimonial "
              style={{ marginRight: "2rem", marginLeft: "2rem" }}
            >
              <div className="pic">
                <img src={img2}></img>
              </div>
              <div className="testimonial-content">
                <p>
                  {" "}
                  With GrabIT, it took me less than 72 hours to find my desired
                  gadget, Order purchase and get it delivered. Awesome Services
                  and highly Recommended, The best online market place to
                  purchase from.
                </p>
              </div>
              <h3 className="testimonial-title">
                <a className="tesm" href="#">
                  <b>Sally Embolo</b>
                </a>
                <small>- Accountant</small>
              </h3>
            </div>
          </Slider>
        </div>
      </div>
    );
  }
}

export default Testimonials;
