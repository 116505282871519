import React, { useState, useEffect } from "react";
import "../App.css";
import Footer from "./footer";
import Navbar from ".";
import axios from "axios";
import { API } from "../config";
const Starter = () => {
  const [subscription, setSubscription] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const subId = window.location.href.split("/")[4];

  useEffect(() => {
    const listSubs = async () => {
      try {
        const subs = await axios.get(`${API}/subscription/${subId}`);
        console.log("subs: ", subs);
        setSubscription(subs.data.subscription);
      } catch (err) {
        console.log("subs", err);
      }
    };
    listSubs();
  }, []);

  const initiatePayment = async (amount) => {
    setIsSubmitting(true);
    try {
      let user = localStorage.getItem("user");
      if (!user) {
        window.location.href = "/signin";
      }
      user = JSON.parse(user);
      const response = await axios.post(
        `${API}/payment/init-subs`,
        {
          userId: user._id,
          subscriptionId: subscription._id,
          message: `You are about to subscribe to GrabIt ${subscription.name} plan`,
          amount,
          token: user.originToken,
        },
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      console.log("response", response);
      // window.location.href = `${response.data.response.link}`;
      window.open(response.data.response.link);
    } catch (error) {
      const message = error.response.data.errors.store;
      console.log("error", error);
      alert(message);
      // if (message === "You already have an active subscription") {

      // }else{

      // }
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid plan">
        <h2 className="h2 planhead">
          <b style={{ textTransform: "uppercase" }}>{subscription.name}</b>
        </h2>
        <div className="line1 zoom-out"></div>
        <p className="para1">{subscription.subDescription}</p>

        <div className="row rowstyle">
          <div className="col-sm-4 colstyle2">
            <div className="card zoom-out">
              <h3 className="h3 h3style">Quarterly(4 Months) </h3>
              <h4 className="h4 amount">
                <b>Amount</b>
              </h4>
              <h5 className="amountplan">
                <b>{subscription.quarterlyAmount}Frs</b>
              </h5>

              <p className="getps">
                Subscribe to our Quarter plan to sell products in our market
                place.
              </p>
              <button
                className="btn btn-success colr btnget"
                disabled={isSubmitting}
                onClick={() => initiatePayment("quarterlyAmount")}
              >
                <b>Make Payments</b>
              </button>
            </div>
          </div>

          <div className="col-sm-4 colstyle2">
            <div className="card zoom-out">
              <h3 className="h3style">6 Months</h3>
              <h4 className="h4 amount">
                <b>Amount</b>
              </h4>
              <h5 className="amountplan">
                <b>{subscription.sixMonthsAmount}Frs</b>
              </h5>

              <p className="getps">
                Subscribe to our Mid Level plan in other to sell products in our
                market place.
              </p>

              <button
                className="btn btn-success colr btnget"
                disabled={isSubmitting}
                onClick={() => initiatePayment("sixMonthsAmount")}
              >
                <b>Make Payments</b>
              </button>
            </div>
          </div>

          <div className="col-sm-4 colstyle2 colside2">
            <div className="card zoom-out">
              <h3 className="h3style">Yearly(1 Year)</h3>

              <h4 className="h4 amount">
                <b>Amount</b>
              </h4>
              <h5 className="amountplan">
                <b>{subscription.yearlyAmount}Frs</b>
              </h5>
              <p className="getps">
                Subscribe to our annual plan in other to sell products in our
                market place.
              </p>
              <button
                className="btn btn-success colr btnget"
                disabled={isSubmitting}
                onClick={() => initiatePayment("yearlyAmount")}
              >
                <b>Make Payments</b>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Starter;
