import React, { useEffect } from "react";
import "../App.css";
import Container1 from "./search";
import Products from "./more-products";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../redux-store/feature/product/productSlice";
import Navbar from "./index";
import Footer from "./footer";
import PromotionProducts from "./more-promo-products";

const Home = () => {
  const products = useSelector((state) => state.product.promotionProducts);
  const dispatch = useDispatch();
  useEffect(() => {
    const listProducts = async () => {
      dispatch(getProducts());
    };
    listProducts();
  }, [dispatch]);
  return (
    <div style={{ overflow: "hidden" }}>
      <Navbar />
      <Container1 />

      <Products />
      {/* {products?.length !== 0 && <PromotionProducts />} */}

      <Footer />
    </div>
  );
};

export default Home;

// import React from "react";
// import "../App.css";
// import { Link } from "react-router-dom";
// import img1 from "../images/bags.jfif";
// import img2 from "../images/iphone.jfif";
// import myImage from "../images/bows.jfif";
// import img3 from "../images/female.jfif";
// import img4 from "../images/shirts.jfif";
// import img5 from "../images/chair.jfif";
// import img6 from "../images/shoes.jfif";
// import img7 from "../images/pc.jfif";
// import Navbar from ".";
// import Footer from "./footer";
// import Items from "./pagination";

// const More = () => {
//   return (
//     <>
//       <Navbar />
//       <div className="back2">
//         <br></br>
//         <br></br>
//       </div>
//       <div className="container-fluid mid">
//         <div className="row rowstyle">
//           <div className="col-sm-3 colstyle">
//             <div className="card zoom-out">
//               <img className="imgw zoom" src={myImage}></img>
//               <div className="rowst">
//                 <div className="row ">
//                   <div className="col">
//                     <h5 className="fontsi lef">
//                       <b>Dishes</b>
//                     </h5>
//                   </div>
//                   <div className="col">
//                     <h5 className="rig fontsi prom">
//                       <b>15,000Frs</b>
//                     </h5>
//                   </div>
//                 </div>
//                 <div className=" butspad">
//                   <Link to="/payment">
//                     {" "}
//                     <button className="btn btn-success colr">
//                       <b>Buy</b>
//                     </button>{" "}
//                   </Link>
//                   <Link to="/cart">
//                     {" "}
//                     <button className="btn btn-success colr carti">
//                       <b>Add to Cart</b>
//                     </button>{" "}
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="col-sm-3 colstyle">
//             <div className="card zoom-out">
//               <img className="imgw zoom" src={img1}></img>
//               <div className="rowst">
//                 <div className="row">
//                   <div className="col">
//                     <h5 className="fontsi lef">
//                       <b>Hand Bag</b>
//                     </h5>
//                   </div>
//                   <div className="col">
//                     <h5 className="rig fontsi prom">
//                       <b>25,000Frs</b>
//                     </h5>
//                   </div>
//                 </div>

//                 <div className="butspad">
//                   <Link to="/payment">
//                     {" "}
//                     <button className="btn btn-success colr">
//                       <b>Buy</b>
//                     </button>{" "}
//                   </Link>
//                   <Link to="/cart">
//                     {" "}
//                     <button className="btn btn-success colr carti">
//                       <b>Add to Cart</b>
//                     </button>{" "}
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="col-sm-3 colstyle">
//             <div className="card zoom-out">
//               <img className="imgw zoom" src={img2} />
//               <div className="rowst">
//                 <div className="row">
//                   <div className="col">
//                     <h5 className="fontsi lef">
//                       <b>Iphone13</b>
//                     </h5>
//                   </div>
//                   <div className="col">
//                     <h5 className="rig fontsi prom">
//                       <b>400,000Frs</b>
//                     </h5>
//                   </div>
//                 </div>

//                 <div className="butspad">
//                   <Link to="/payment">
//                     {" "}
//                     <button className="btn btn-success colr">
//                       <b>Buy</b>
//                     </button>{" "}
//                   </Link>
//                   <Link to="/cart">
//                     {" "}
//                     <button className="btn btn-success colr carti">
//                       <b>Add to Cart</b>
//                     </button>{" "}
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="col-sm-3 colstyle colside">
//             <div className="card zoom-out">
//               <img className="imgw zoom" src={img3} />
//               <div className="rowst">
//                 <div className="row">
//                   <div className="col">
//                     <h5 className="lef fontsi">
//                       <b>Shoes</b>
//                     </h5>
//                   </div>
//                   <div className="col">
//                     <h5 className="rig fontsi prom">
//                       <b>10,000Frs</b>
//                     </h5>
//                   </div>
//                 </div>

//                 <div className="butspad">
//                   <Link to="/payment">
//                     {" "}
//                     <button className="btn btn-success colr">
//                       <b>Buy</b>
//                     </button>{" "}
//                   </Link>
//                   <Link to="/cart">
//                     {" "}
//                     <button className="btn btn-success colr carti">
//                       <b>Add to Cart</b>
//                     </button>{" "}
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="row rowstyle">
//           <div className="col-sm-3 colstyle">
//             <div className="card zoom-out">
//               <img className="imgw zoom" src={img4} />
//               <div className="rowst">
//                 <div className="row">
//                   <div className="col">
//                     <h5 className="lef fontsi">
//                       <b>Shirt</b>
//                     </h5>
//                   </div>
//                   <div className="col">
//                     <h5 className="rig fontsi prom">
//                       <b>5000Frs</b>
//                     </h5>
//                   </div>
//                 </div>

//                 <div className="butspad">
//                   <Link to="/payment">
//                     {" "}
//                     <button className="btn btn-success colr">
//                       <b>Buy</b>
//                     </button>{" "}
//                   </Link>
//                   <Link to="/cart">
//                     {" "}
//                     <button className="btn btn-success colr carti">
//                       <b>Add to Cart</b>
//                     </button>{" "}
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="col-sm-3 colstyle">
//             <div className="card zoom-out">
//               <img className="imgw zoom" src={img5} />
//               <div className="rowst">
//                 <div className="row">
//                   <div className="col">
//                     <h5 className="lef fontsi">
//                       <b>Office Chair</b>
//                     </h5>
//                   </div>
//                   <div className="col">
//                     <h5 className="rig fontsi prom">
//                       <b>30,000frs</b>
//                     </h5>
//                   </div>
//                 </div>

//                 <div className="butspad">
//                   <Link to="/payment">
//                     {" "}
//                     <button className="btn btn-success colr">
//                       <b>Buy</b>
//                     </button>{" "}
//                   </Link>
//                   <Link to="/cart">
//                     {" "}
//                     <button className="btn btn-success colr carti">
//                       <b>Add to Cart</b>
//                     </button>{" "}
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="col-sm-3 colstyle">
//             <div className="card zoom-out">
//               <img className="imgw zoom" src={img6} />
//               <div className="rowst">
//                 <div className="row">
//                   <div className="col">
//                     <h5 className="lef fontsi">
//                       <b>Shoes</b>
//                     </h5>
//                   </div>
//                   <div className="col">
//                     <h5 className="rig fontsi prom">
//                       <b>17,000Frs</b>
//                     </h5>
//                   </div>
//                 </div>

//                 <div className="butspad">
//                   <Link to="/payment">
//                     {" "}
//                     <button className="btn btn-success colr">
//                       <b>Buy</b>
//                     </button>{" "}
//                   </Link>
//                   <Link to="/cart">
//                     {" "}
//                     <button className="btn btn-success colr carti">
//                       <b>Add to Cart</b>
//                     </button>{" "}
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="col-sm-3 colstyle colside">
//             <div className="card zoom-out">
//               <img className="imgw zoom" src={img7} />
//               <div className="rowst">
//                 <div className="row">
//                   <div className="col">
//                     <h5 className="lef fontsi">
//                       <b>Macbook</b>
//                     </h5>
//                   </div>
//                   <div className="col">
//                     <h5 className="rig fontsi prom">
//                       <b>900,000Frs</b>
//                     </h5>
//                   </div>
//                 </div>

//                 <div className="butspad">
//                   <Link to="/payment">
//                     {" "}
//                     <button className="btn btn-success colr">
//                       <b>Buy</b>
//                     </button>{" "}
//                   </Link>
//                   <Link to="/cart">
//                     {" "}
//                     <button className="btn btn-success colr carti">
//                       <b>Add to Cart</b>
//                     </button>{" "}
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="row rowstyle">
//           <div className="col-sm-3 colstyle">
//             <div className="card zoom-out">
//               <img className="imgw zoom" src={myImage}></img>
//               <div className="rowst">
//                 <div className="row ">
//                   <div className="col">
//                     <h5 className="fontsi lef">
//                       <b>Dishes</b>
//                     </h5>
//                   </div>
//                   <div className="col">
//                     <h5 className="rig fontsi prom">
//                       <b>15,000Frs</b>
//                     </h5>
//                   </div>
//                 </div>
//                 <div className=" butspad">
//                   <Link to="/payment">
//                     {" "}
//                     <button className="btn btn-success colr">
//                       <b>Buy</b>
//                     </button>{" "}
//                   </Link>
//                   <Link to="/cart">
//                     {" "}
//                     <button className="btn btn-success colr carti">
//                       <b>Add to Cart</b>
//                     </button>{" "}
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="col-sm-3 colstyle">
//             <div className="card zoom-out">
//               <img className="imgw zoom" src={img1}></img>
//               <div className="rowst">
//                 <div className="row">
//                   <div className="col">
//                     <h5 className="fontsi lef">
//                       <b>Hand Bag</b>
//                     </h5>
//                   </div>
//                   <div className="col">
//                     <h5 className="rig fontsi prom">
//                       <b>25,000Frs</b>
//                     </h5>
//                   </div>
//                 </div>

//                 <div className="butspad">
//                   <Link to="/payment">
//                     {" "}
//                     <button className="btn btn-success colr">
//                       <b>Buy</b>
//                     </button>{" "}
//                   </Link>
//                   <Link to="/cart">
//                     {" "}
//                     <button className="btn btn-success colr carti">
//                       <b>Add to Cart</b>
//                     </button>{" "}
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="col-sm-3 colstyle">
//             <div className="card zoom-out">
//               <img className="imgw zoom" src={img2} />
//               <div className="rowst">
//                 <div className="row">
//                   <div className="col">
//                     <h5 className="fontsi lef">
//                       <b>Iphone13</b>
//                     </h5>
//                   </div>
//                   <div className="col">
//                     <h5 className="rig fontsi prom">
//                       <b>400,000Frs</b>
//                     </h5>
//                   </div>
//                 </div>

//                 <div className="butspad">
//                   <Link to="/payment">
//                     {" "}
//                     <button className="btn btn-success colr">
//                       <b>Buy</b>
//                     </button>{" "}
//                   </Link>
//                   <Link to="/cart">
//                     {" "}
//                     <button className="btn btn-success colr carti">
//                       <b>Add to Cart</b>
//                     </button>{" "}
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="col-sm-3 colstyle colside">
//             <div className="card zoom-out">
//               <img className="imgw zoom" src={img3} />
//               <div className="rowst">
//                 <div className="row">
//                   <div className="col">
//                     <h5 className="lef fontsi">
//                       <b>Shoes</b>
//                     </h5>
//                   </div>
//                   <div className="col">
//                     <h5 className="rig fontsi prom">
//                       <b>10,000Frs</b>
//                     </h5>
//                   </div>
//                 </div>

//                 <div className="butspad">
//                   <Link to="/payment">
//                     {" "}
//                     <button className="btn btn-success colr">
//                       <b>Buy</b>
//                     </button>{" "}
//                   </Link>
//                   <Link to="/cart">
//                     {" "}
//                     <button className="btn btn-success colr carti">
//                       <b>Add to Cart</b>
//                     </button>{" "}
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="row rowstyle">
//           <div className="col-sm-3 colstyle">
//             <div className="card zoom-out">
//               <img className="imgw zoom" src={img4} />
//               <div className="rowst">
//                 <div className="row">
//                   <div className="col">
//                     <h5 className="lef fontsi">
//                       <b>Shirt</b>
//                     </h5>
//                   </div>
//                   <div className="col">
//                     <h5 className="rig fontsi prom">
//                       <b>5000Frs</b>
//                     </h5>
//                   </div>
//                 </div>

//                 <div className="butspad">
//                   <Link to="/payment">
//                     {" "}
//                     <button className="btn btn-success colr">
//                       <b>Buy</b>
//                     </button>{" "}
//                   </Link>
//                   <Link to="/cart">
//                     {" "}
//                     <button className="btn btn-success colr carti">
//                       <b>Add to Cart</b>
//                     </button>{" "}
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="col-sm-3 colstyle">
//             <div className="card zoom-out">
//               <img className="imgw zoom" src={img5} />
//               <div className="rowst">
//                 <div className="row">
//                   <div className="col">
//                     <h5 className="lef fontsi">
//                       <b>Office Chair</b>
//                     </h5>
//                   </div>
//                   <div className="col">
//                     <h5 className="rig fontsi prom">
//                       <b>30,000frs</b>
//                     </h5>
//                   </div>
//                 </div>

//                 <div className="butspad">
//                   <Link to="/payment">
//                     {" "}
//                     <button className="btn btn-success colr">
//                       <b>Buy</b>
//                     </button>{" "}
//                   </Link>
//                   <Link to="/cart">
//                     {" "}
//                     <button className="btn btn-success colr carti">
//                       <b>Add to Cart</b>
//                     </button>{" "}
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="col-sm-3 colstyle">
//             <div className="card zoom-out">
//               <img className="imgw zoom" src={img6} />
//               <div className="rowst">
//                 <div className="row">
//                   <div className="col">
//                     <h5 className="lef fontsi">
//                       <b>Shoes</b>
//                     </h5>
//                   </div>
//                   <div className="col">
//                     <h5 className="rig fontsi prom">
//                       <b>17,000Frs</b>
//                     </h5>
//                   </div>
//                 </div>

//                 <div className="butspad">
//                   <Link to="/payment">
//                     {" "}
//                     <button className="btn btn-success colr">
//                       <b>Buy</b>
//                     </button>{" "}
//                   </Link>
//                   <Link to="/cart">
//                     {" "}
//                     <button className="btn btn-success colr carti">
//                       <b>Add to Cart</b>
//                     </button>{" "}
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="col-sm-3 colstyle colside">
//             <div className="card zoom-out">
//               <img className="imgw zoom" src={img7} />
//               <div className="rowst">
//                 <div className="row">
//                   <div className="col">
//                     <h5 className="lef fontsi">
//                       <b>Macbook</b>
//                     </h5>
//                   </div>
//                   <div className="col">
//                     <h5 className="rig fontsi prom">
//                       <b>900,000Frs</b>
//                     </h5>
//                   </div>
//                 </div>

//                 <div className="butspad">
//                   <Link to="/payment">
//                     {" "}
//                     <button className="btn btn-success colr">
//                       <b>Buy</b>
//                     </button>{" "}
//                   </Link>
//                   <Link to="/cart">
//                     {" "}
//                     <button className="btn btn-success colr carti">
//                       <b>Add to Cart</b>
//                     </button>{" "}
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <Items />
//       <Footer />
//     </>
//   );
// };

// export default More;
