import React from "react";
import "../App.css";
import Footer from "./footer";
import Navbar from ".";
import { Link } from "react-router-dom";
import axios from "axios";
import { API } from "../config";
const Premium = () => {
  const initiatePayment = async (amount) => {
    try {
      const response = await axios.post(`${API}/fapshi/initiate-pay`);
      console.log("response", response);
      window.open(response.data.resp.link);
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <>
      <Navbar />
      <div className="container-fluid start sign">
        <h2 className="h2  planhead">
          <b>PREMIUM PLAN</b>
        </h2>
        <div className="line1 zoomout"></div>
        <p className="para1">
          Choose A Premium Subscription Plan to Sell Products
        </p>

        <div className="row rowstyle">
          <div className="col-sm-4 colstyle2">
            <div className="card zoom-out">
              <h3 className="h3 h3style">Quarterly(4 Months) </h3>
              <h4 className="h4 amount">
                <b>Amount</b>
              </h4>
              <h5 className="amountplan">
                <b>9,000Frs</b>
              </h5>

              <p className="getps">
                Subscribe to our Quarter plan to sell products in our market
                place.
              </p>
              <button
                className="btn btn-success colr btnget"
                onClick={initiatePayment}
              >
                <b>Make Payments</b>
              </button>
            </div>
          </div>

          <div className="col-sm-4 colstyle2">
            <div className="card zoom-out">
              <h3 className="h3style">6 Months</h3>
              <h4 className="h4 amount">
                <b>Amount</b>
              </h4>
              <h5 className="amountplan">
                <b>12,000Frs</b>
              </h5>

              <p className="getps">
                Subscribe to our Mid Level plan in other to sell products in our
                market place.
              </p>

              <button
                className="btn btn-success colr btnget"
                onClick={initiatePayment}
              >
                <b>Make Payments</b>
              </button>
            </div>
          </div>

          <div className="col-sm-4 colstyle2 colside2">
            <div className="card zoom-out">
              <h3 className="h3style">Yearly(1 Year)</h3>

              <h4 className="h4 amount">
                <b>Amount</b>
              </h4>
              <h5 className="amountplan">
                <b>15,000Frs</b>
              </h5>
              <p className="getps">
                Subscribe to our annual plan in other to sell products in our
                market place.
              </p>
              <button
                className="btn btn-success colr btnget"
                onClick={initiatePayment}
              >
                <b>Make Payments</b>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Premium;
