import React from "react";
import "../App.css";
import Container1 from "./container";
import Navbar from "./index";
import Footer from "./footer";
import axios from "axios";
import { useState, useEffect } from "react";
import { API } from "../config";

const Verify = () => {
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const url = window.location.href.split("/");
  const path = `${API}/user/verify/${url[4]}/${url[5]}/${url[6]}`;

  console.log("path", path);

  const verifyEmail = async () => {
    setIsSubmitting(true);
    try {
      setError("");
      const result = await axios.get(path);
      console.log("result:", result);
      if (result.status === 200) {
        window.location.href = "/signin";
      } else {
        setError("An error occured. Please make sure it is a valide url");
      }
    } catch (error) {
      setError("An error occured. Please make sure it is a valide url");
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <Navbar />

      <div
        className="row col-12 "
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100vw",
          paddingRight: "1rem",
          minHeight: "20rem",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginLeft: "2rem",
            }}
            className="row"
          >
            <div>
              <p style={{ color: "red", textAlign: "center" }}>{error}</p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginLeft: "2rem",
            }}
            className="row"
          >
            <button
              style={{ width: "11rem" }}
              className="signup btn btn-success"
              onClick={verifyEmail}
              disabled={isSubmitting}
            >
              Verify your email
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Verify;
