import React, { useState } from "react";
import "../App.css";
import { useDispatch } from "react-redux";
import {
  getProducts,
  searchProducts,
} from "../redux-store/feature/product/productSlice";

const Container1 = () => {
  const [query, setQuery] = useState();
  const dispatch = useDispatch();
  const search = () => {
    dispatch(searchProducts(query));
  };

  const resetProducts = (value) => {
    setQuery(value);
    if (value === "") {
      dispatch(getProducts());
    }
  };

  return (
    <>
      <div className="container-fluid sign bg-primary">
        <div
          className="row col-12 hero"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100vw",
            paddingRight: "1rem",
          }}
        >
          <div className="col-md-8 col-lg-6">
            <div className="row">
              <h4 className="h4 co zoom-out buya">
                <p className="heroText" style={{ textAlign: "center" }}>
                  <b>Premium Search</b>
                </p>
              </h4>
            </div>
            <form
              className="input-group"
              action=""
              style={{ marginLeft: ".8rem" }}
            >
              <input
                type="text"
                className="form-control"
                placeholder="What are you looking for......?"
                onChange={(e) => resetProducts(e.target.value)}
              />
              <div className="input-group-append">
                <button
                  onClick={search}
                  className="seh  btn btn-success"
                  type="button"
                >
                  <i className="fa fa-search"></i>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default Container1;
