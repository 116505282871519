import React, { useEffect, useState } from "react";
import "../App.css";
import Footer from "../Home/footer";
import Testimonials from "../Home/testimonial";
import Navbar from "../Home";
import Available from "./shops";
import Shopc1 from "./shopcontainer";
import Own from "./owning";
import Secondary from "../Home/secondary";
import UserProducts from "./user-products";
import axios from "axios";
import { API } from "../config";
const Shop = () => {
  const [user, setUser] = useState(null);
  useEffect(() => {
    const getStore = async () => {
      try {
        let user = localStorage.getItem("user");
        if (!user) {
          return;
        } else {
          user = JSON.parse(user);
          if (user.role === "seller") {
            const result = await axios.get(`${API}/store/user/${user._id}`);
            console.log("result", result);
            if (result && result.data.store[0].length !== 0) {
              setUser(true);
            }
          }
        }
      } catch (error) {}
    };
    getStore();
  }, []);
  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <Navbar />

        {user ? (
          <UserProducts />
        ) : (
          <>
            <Shopc1 />
            <Own />
            <Testimonials />
            <div className="back">
              <br></br>
              <br></br>
            </div>
            <Secondary />
            <div className="back2">
              <br></br>
              <br></br>
            </div>
            <div className="shopsf">
              <br></br>
              <br></br>
              <br></br>
              <br></br>
            </div>
          </>
        )}

        <Footer />
      </div>
    </>
  );
};
export default Shop;
