import React, { useState, useEffect } from "react";
import Tesseract from "tesseract.js";
import Footer from "../Home/footer";
import Navbar from "../Home";
import { Link } from "react-router-dom";
import axios from "axios";
import { API } from "../config";
import WebcamCapture from "./WebcamCapture";

const VerifyDoc = () => {
  const [user, setUser] = useState(null);
  const [store, setStore] = useState(null);

  const [text, setText] = useState("");
  const [img, setImg] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getUser = async () => {
      try {
        let user = localStorage.getItem("user");
        if (!user) {
          window.location.href = "/signin";
        } else {
          const result = await axios.get(
            `${API}/store/user/${JSON.parse(user)._id}`
          );
          setUser(JSON.parse(user));
          setStore(result.data.store[0]);
        }
      } catch (err) {}
    };
    getUser();
  }, []);

  const handleImage = async (img) => {
    try {
      setImg(URL.createObjectURL(img));
      setLoading(true);

      const new_store = await axios.post(
        `${API}/store/verify`,
        {
          userId: user._id,
          id: store._id,
          token: user.originToken,
        },
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );

      setStore(new_store.data.store);

      Tesseract.recognize(img, "eng", {
        logger: (m) => console.log(m), // Optional logger for progress
      })
        .then(({ data: { text } }) => {
          setText(text);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    } catch (error) {
      console.log("error:", error);
    }
  };

  return (
    <div
      style={{
        overflow: "hidden",
      }}
    >
      <Navbar />

      <section
        className="profile"
        style={{
          backgroundColor: "#f4f5f7",
          marginTop: "0rem",
          overflow: "hidden",
        }}
      >
        {user ? (
          <div className="h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div
                className="col-4 col-lg-6 mb-4 mb-lg-0"
                style={{ width: "fit-content" }}
              >
                {/* <div className="text-center">Snap front view of ID Card</div> */}
                <WebcamCapture handleImage={handleImage} userId={user._id} />
                <div>
                  {img && (
                    <>
                      <img
                        src={img}
                        style={{ width: "300px", marginTop: "20px" }}
                        alt=""
                      />
                    </>
                  )}

                  <div className="pt-2">
                    {text ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div>
                          <div>
                            <p style={{ color: "green", fontWeight: "500" }}>
                              Document Valid
                            </p>
                          </div>
                          <Link to="/profile" className="btn btn-success">
                            Back to profile
                          </Link>
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {loading && <div>Scanning...</div>}
                        {text && (
                          <p style={{ color: "red" }}>Document not Valid</p>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col col-lg-6 mb-4 mb-lg-0">
                <div className="card mb-3" style={{ borderRadius: ".5rem" }}>
                  <div className="row g-0">
                    <div className="col-md-8">
                      <div className="card-body p-4">
                        <h5
                          style={{
                            marginBottom: "1rem",
                          }}
                        >
                          No user found
                        </h5>
                        <Link to="/signin">
                          <button
                            className="btn"
                            style={{
                              width: "8rem",
                              padding: ".3rem rem",
                              background: "green",
                              color: "white",
                              height: "2.5rem",
                            }}
                          >
                            <b
                              style={{
                                color: "white",
                              }}
                            >
                              SignIn
                            </b>
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>

      <Footer />
    </div>
  );
};

export default VerifyDoc;
