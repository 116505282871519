import React from "react";
import "../App.css";
import { Link } from "react-router-dom";
import SignInSingUp from "../Home/singin";

const Buyc1 = () => {
  return (
    <div className="container-fluid sign bg-primary heig">
      <div className="sign1">
        <SignInSingUp />
      </div>

      <h2 className="h2 coa">
        <b>BUY PRODUCTS </b>
      </h2>
      <div className="line1 zoom-out"></div>
      <p className="pb">
        Get connected instantly and directly with prospective shop owners,
        businessess and purchase your desired product.
      </p>
    </div>
  );
};
export default Buyc1;
