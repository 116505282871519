import React from "react";
import "../App.css";
import { useState } from "react";
import { validateEmail } from "../Home/utils";
import axios from "axios";
import { API } from "../config";

function RegisterShop() {
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [image, setImage] = useState("");
  // const [legalDcocumet, setLegalDocument] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  // const [identificationDocument, setidentificationDocument] = useState("");
  // const [identificationDocumentType, setIdentificationDocumentType] =
  //   useState("National ID Card");

  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getIsFormValid = () => {
    return (
      name &&
      validateEmail(email) &&
      location &&
      phoneNumber &&
      image &&
      // identificationDocumentType &&
      // identificationDocument &&
      // legalDcocumet &&
      !isSubmitting
    );
  };

  const clearForm = () => {
    setName("");
    setEmail("");
    setLocation("");
    setPhoneNumber("");
    // setLegalDocument("");
    // setidentificationDocument("");
    // setIdentificationDocumentType("");
    setImage("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let user = localStorage.getItem("user");
    if (!user) {
      window.location.href = "/signin";
    }
    user = JSON.parse(user);
    setIsSubmitting(true);

    // sending post request to upload file
    const formData = new FormData();
    formData.append("myFile", image);
    axios
      .post(`${API}/upload/image`, formData, {
        headers: {
          "content-tupe": "multipart/form-data",
        },
      })
      .then((res) => {})
      .catch((err) => {});

    // uploading legal docs
    // const legalDcocumetData = new FormData();
    // legalDcocumetData.append("myFile", legalDcocumet);
    // axios
    //   .post(`${API}/upload/legal-document`, legalDcocumetData, {
    //     headers: {
    //       "content-tupe": "multipart/form-data",
    //     },
    //   })
    //   .then((res) => {})
    //   .catch((err) => {});

    // uploading identification docs
    // const identificationDocumentData = new FormData();
    // identificationDocumentData.append("myFile", identificationDocument);
    // axios
    //   .post(
    //     `${API}/upload/identification-document`,
    //     identificationDocumentData,
    //     {
    //       headers: {
    //         "content-tupe": "multipart/form-data",
    //       },
    //     }
    //   )
    //   .then((res) => {})
    //   .catch((err) => {});

    const shop = {
      name,
      location,
      image: image.name,
      email,
      phoneNumber,
      // identificationDocumentType,
      // legalDcocumet: legalDcocumet.name,
      // identificationDocument: identificationDocument.name,
      storeType: "Physical shop",
      userId: user._id,
      token: user.originToken,
    };

    try {
      const result = await axios.post(`${API}/store/create`, shop, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      if (result.status === 201) {
        window.location.href = "/post";
      } else {
        const err = result.error.response.data.errors;
        setError(err);
        if (err.store === "unauthorized") {
          window.location.href = "/signin";
        }
      }
    } catch (error) {
      const err = error.response.data.errors;
      setError(err);
      if (err.store === "unauthorized") {
        window.location.href = "/signin";
      }
    }

    setIsSubmitting(false);
    clearForm();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
  };

  const handleLegalDoc = (e) => {
    const file = e.target.files[0];
    // setLegalDocument(file);
  };
  const handleIdendificationDoc = (e) => {
    const file = e.target.files[0];
    // setidentificationDocument(file);
  };

  return (
    <div className="container-fluid ing sign">
      <div className="App2">
        <form onSubmit={handleSubmit}>
          <div className="formbs">
            <fieldset>
              <h2 className="formas h2">
                <b>Register A Shop</b>
              </h2>
              <div className="Field">
                <label className="labels">Shop Name</label>
                <input
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  placeholder="Shop name"
                />
              </div>{" "}
              <div className="Field">
                <label className="labels">Shop Location</label>
                <input
                  value={location}
                  onChange={(e) => {
                    setLocation(e.target.value);
                  }}
                  placeholder="Shop Location"
                />
              </div>
              {/* <div className="Field">
                <label className="labels">Legal Documents</label>
                <input
                  type="file"
                  onChange={(e) => {
                    handleLegalDoc(e);
                  }}
                  accept=".pdf"
                  placeholder="Legal documents"
                />
              </div> */}
              <div className="Field">
                <label className="labels">Email address</label>
                <input
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  placeholder="Email address"
                />
              </div>
              <div className="Field">
                <label className="labels">Phone Number</label>
                <input
                  value={phoneNumber}
                  onChange={(e) => {
                    setPhoneNumber(e.target.value);
                  }}
                  placeholder="Phone Number"
                />
              </div>
              {/* <div className="Field">
                <label className="labels">identificationDocumentType</label>
                <select
                  value={identificationDocumentType}
                  onChange={(e) => {
                    setIdentificationDocumentType(e.target.value);
                  }}
                >
                  <option value="National ID Card">National ID Card</option>
                  <option value="Passport">Passport</option>
                  <option value="Drivers Lisense">Drivers Lisense</option>
                </select>
              </div>
              <div className="Field">
                <label className="labels">Identification Documents</label>
                <input
                  type="file"
                  onChange={(e) => {
                    handleIdendificationDoc(e);
                  }}
                  accept=".pdf"
                  placeholder="National ID card or Passport or Drivers Lisense"
                />
              </div> */}
              <div className="Field">
                <label className="labels"> Shop Image</label>
                <input
                  onChange={(e) => {
                    setImage(e.target.files[0]);
                  }}
                  placeholder="Upload"
                  type="file"
                />
              </div>
              {error && (
                <div style={{ color: "red", paddingBottom: ".1rem" }}>
                  {Object.keys(error).map((keyName, i) => (
                    <p>
                      {/* {Object.keys(error)[i]} : {error[keyName]} */}
                      {error[keyName]}
                    </p>
                  ))}
                </div>
              )}
              <button
                className="formbutss"
                type="submit"
                disabled={!getIsFormValid()}
              >
                Register
              </button>
            </fieldset>
          </div>
        </form>
      </div>
    </div>
  );
}

export default RegisterShop;
