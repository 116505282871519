import React, { useState, useEffect } from "react";
import "../App.css";
import Navbar from ".";
import Footer from "./footer";
import axios from "axios";
import { API } from "../config";

function Content() {
  const [user, setUser] = useState({
    id: "",
    firstname: "",
    lastname: "",
    location: "",
    phoneNumber: "",
    role: "",
    token: "",
    originToken: "",
    carCategory: "",
    numberplateLicence: "",
  });
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [signUpError, setSignUpError] = useState({});

  useEffect(() => {
    const listUser = async () => {
      try {
        let user = localStorage.getItem("user");
        if (!user) {
          window.location.href = "/signin";
        } else {
          user = JSON.parse(user);
          setUser({
            id: user._id,
            firstname: user.firstname,
            lastname: user.lastname,
            location: user.location,
            phoneNumber: user.phoneNumber,
            token: user.originToken,
            originToken: user.token,
            role: user.role,
            carCategory: "",
            numberplateLicence: "",
          });
        }
      } catch (err) {}
    };
    listUser();
  }, []);

  const handleChange = (event) => {
    setUser({ ...user, [event.name]: event.value });
  };

  const getIsFormValid = () => {
    return (
      user.firstname &&
      user.lastname &&
      user.location &&
      user.phoneNumber &&
      !isSigningUp
    );
  };

  const clearForm = () => {
    setUser({
      firstname: "",
      lastname: "",
      location: "",
      phoneNumber: "",
      role: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSigningUp(true);
    setSignUpError("");
    try {
      console.log("user: ", user);
      const editUser = await axios.post(`${API}/user/update`, user, {
        headers: { Authorization: `Bearer ${user.originToken}` },
      });

      if (editUser.data.success) {
        localStorage.removeItem("user");
        localStorage.setItem("user", JSON.stringify(editUser.data.user));
        console.log("currentUser", editUser.data.user);
        window.location.href = "/profile";
      } else {
        setSignUpError(editUser.error.response.data.errors);
      }
      setIsSigningUp(false);
    } catch (error) {
      console.log("editUser", error);
    }
    // clearForm();
  };

  return (
    <>
      <div className="container-fluid ing sign">
        <div className="col-sm-5 margina">
          <div className="App mx-auto">
            <form onSubmit={handleSubmit}>
              <div className="formb">
                <fieldset>
                  <h2 className="forma h2">
                    <b>Create An Account</b>
                  </h2>
                  <div className="line zoom-out"></div>

                  <div className="Field">
                    <label>
                      First name <sup>*</sup>
                    </label>
                    <input
                      value={user.firstname}
                      name="firstname"
                      onChange={(e) => handleChange(e.target)}
                      placeholder="First name"
                    />
                  </div>
                  <div className="Field">
                    <label>Last name</label>
                    <input
                      value={user.lastname}
                      name="lastname"
                      onChange={(e) => handleChange(e.target)}
                      placeholder="Last name"
                    />
                  </div>

                  <div className="Field">
                    <label>
                      Location <sup>*</sup>
                    </label>
                    <input
                      value={user.location}
                      name="location"
                      onChange={(e) => handleChange(e.target)}
                      placeholder="Location"
                    />
                  </div>

                  <div className="Field">
                    <label>
                      Phone Number <sup>*</sup>
                    </label>
                    <input
                      value={user.phoneNumber}
                      name="phoneNumber"
                      onChange={(e) => handleChange(e.target)}
                      placeholder="Phone Number"
                    />
                  </div>

                  <div className="Field">
                    <label>
                      Role <sup>*</sup>
                    </label>
                    <select
                      value={user.role}
                      name="role"
                      onChange={(e) => handleChange(e.target)}
                    >
                      <option value="seller">Seller</option>
                      <option value="buyer">Buyer</option>
                    </select>
                  </div>

                  {signUpError && (
                    <div style={{ color: "red", paddingBottom: ".1rem" }}>
                      {Object.keys(signUpError).map((keyName, i) => (
                        <p>{signUpError[keyName]}</p>
                      ))}
                    </div>
                  )}

                  <button
                    className="formbuts text-center"
                    type="submit"
                    disabled={!getIsFormValid()}
                  >
                    Edit
                  </button>
                </fieldset>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

const Edit = () => {
  return (
    <>
      <Navbar />
      <Content />
      <Footer />
    </>
  );
};

export default Edit;
