import React from "react";
import "../App.css";
import { Link } from "react-router-dom";
const SignInSingUp = () => {
  let user = null;
  let userInfo = localStorage.getItem("user");
  if (userInfo) {
    user = JSON.parse(userInfo);
  }
  return (
    <>
      {user ? (
        <div style={{ marginTop: "2.5rem" }}></div>
      ) : (
        <div className="sign1">
          <Link to="../signup">
            <button className="signup btn btn-success" type="submit">
              <b>SignUp</b>
            </button>
          </Link>
          <Link to="../signin">
            <button className="signin btn btn-success" type="submit">
              <b>SignIn</b>
            </button>
          </Link>
        </div>
      )}
    </>
  );
};
export default SignInSingUp;
