import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import productService from "./productService";

const initialState = {
  products: [],
  promotionProducts: [],
};

export const getProducts = createAsyncThunk("product/getProducts", async () => {
  return await productService.getProducts();
});
export const searchProducts = createAsyncThunk(
  "product/search",
  async (query) => {
    return await productService.searchProducts(query);
  }
);
export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    reset: (state) => {
      state.products = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state, action) => {
        // console.log("pend", action);
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        // console.log("ful", action);
        if (action.payload.success) {
          state.products = action.payload.products;
          state.promotionProducts = action.payload.promotionProducts;
        }
      })
      .addCase(getProducts.rejected, (state, action) => {
        // console.log("actions", action);
        // state.products = null;
      })
      .addCase(searchProducts.pending, (state, action) => {
        // console.log("pend", action);
      })
      .addCase(searchProducts.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.products = action.payload.products;
          state.promotionProducts = action.payload.promotionProducts;
        }
      })
      .addCase(searchProducts.rejected, (state, action) => {
        // console.log("actions", action);
        // state.products = null;
      });
  },
});

export default productSlice.reducer;
export const { reset } = productSlice.actions;
