import axios from "axios";
import { API } from "../../../config";
const getProducts = async () => {
  try {
    const result = await axios.get(`${API}/product/products`);

    const newProducts = [];
    const promotionProducts = [];

    for (const product of result?.data?.products) {
      if (product?.promotionPrice && product?.promotionPrice !== 0) {
        promotionProducts.push(product);
      } else {
        newProducts.push(product);
      }
    }

    return { success: true, products: newProducts, promotionProducts };
  } catch (error) {
    return { success: false, error: error };
  }
};
const searchProducts = async (query) => {
  try {
    const result = await axios.get(`${API}/search/products/${query}`);

    const newProducts = [];
    const promotionProducts = [];

    for (const product of result?.data?.products) {
      if (product?.promotionPrice && product?.promotionPrice !== 0) {
        promotionProducts.push(product);
      } else {
        newProducts.push(product);
      }
    }

    return { success: true, products: newProducts, promotionProducts };
  } catch (error) {
    // console.log("result q", error);
    return { success: false, error: error };
  }
};

const userService = {
  getProducts,
  searchProducts,
};
export default userService;
