import React from "react";
import '../App.css';
import Navbar from ".";
import Creata from "./creacta";
import Footer from "./footer";



const Signup = () => { 
  
  return ( 
   <>
   
   <Navbar />
   <Creata />
   <Footer />
   </>
  ); 
 } 

export default Signup;