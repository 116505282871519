import React, { useState } from "react";
import "./App.css";
import {
  Routes,
  Route,
  Switch,
  BrowserRouter as Router,
  BrowserRouter,
} from "react-router-dom";
import "./App.css";
import Home from "./Home/home";
import About from "./About/about";
import Shop from "./Shop/shop";
import Buy from "./Buy/buy";
import useScreenSize from "./useScreenSize";
import Signup from "./Home/signup";
import Signin from "./Home/signin";
import Creata from "./Home/creacta";
import Forgot from "./Home/forgot";
import ShopRegistration from "./Shop/register";
import ShopCreate from "./Shop/create";
import Post from "./Shop/post";
import Payment from "./Home/payment";
import Cart from "./Home/cart";
import Starter from "./Home/plan";
import Business from "./Home/plan2";
import Premium from "./Home/plan3";
import Verify from "./Home/verify";
import NewComponent from "./i18n/NewComponent";
import LanguageContext from "./i18n/LanguageContext";
import translations from "./i18n";
import i18n from "./i18n";
import More from "./Home/more";
import Momo from "./Home/momo";
import ResetPassword from "./Home/resetpas";
import Items from "./Home/pagination";
import Profile from "./Home/profile";
import Edit from "./Home/edit";
import Orders from "./Home/orders";
import Payments from "./Home/payments";
import MyOrders from "./Home/my-orders";
import ProductDetails from "./Home/product";
import EditProduct from "./Shop/edit-product";
import VerifyDocs from "./VerifyDocs";
import VerifyDoc from "./VerifyDocs/verify";
let frenchCodes = ["fr-FR", "fr-fr", "fr"];
let language = navigator.language || navigator.userLanguage;
if (!frenchCodes.includes(language)) {
  language = "en-US";
}

function App() {
  const [lng, setLng] = useState(language);

  return (
    <LanguageContext.Provider value={lng}>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/buy" element={<Buy />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="../buy" element={<Creata />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/register" element={<ShopRegistration />} />
          <Route path="/create" element={<ShopCreate />} />
          <Route path="/post" element={<Post />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/plan/:id" element={<Starter />} />
          <Route path="/plan2" element={<Business />} />
          <Route path="/plan3" element={<Premium />} />
          <Route path="/more" element={<More />} />
          <Route path="/edit" element={<Edit />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/product/:id" element={<ProductDetails />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/my-orders" element={<MyOrders />} />
          <Route path="/edit/:id" element={<EditProduct />} />
          <Route path="/payments" element={<Payments />} />
          <Route path="/verify-store" element={<VerifyDocs />} />
          <Route path="/verify-doc" element={<VerifyDoc />} />
          <Route path="/verify/:value/:userId/:token" element={<Verify />} />
          <Route
            path="/resetpassword/:value/:userId/:token"
            element={<ResetPassword />}
          />
        </Routes>
      </BrowserRouter>
    </LanguageContext.Provider>
  );
}

export default App;
