import React, { useState, useEffect } from "react";
import "../App.css";
import Footer from "./footer";
import Navbar from ".";
import { Link } from "react-router-dom";
import axios from "axios";
import { API } from "../config";
const MyOrders = () => {
  const [order, setOrder] = useState([]);
  const [store, setStore] = useState({});
  const [isConfirming, setIsConfirming] = useState(false);
  let user = localStorage.getItem("user");
  if (user) {
    user = JSON.parse(user);
  }

  useEffect(() => {
    const listCarts = async () => {
      try {
        const result = await axios.get(`${API}/store/user/${user?._id}`);
        setStore(result.data.store[0]);
        const orders = await axios.post(
          `${API}/order/user/list/`,
          {
            userId: user?._id,
            token: user.originToken,
          },
          {
            headers: { Authorization: `Bearer ${user.token}` },
          }
        );
        setOrder(orders.data.orders);
      } catch (err) {
        console.log("cart", err);
      }
    };
    listCarts();
  }, []);

  const confirmDelivery = async (id, deliveryUserId) => {
    setIsConfirming(true);
    try {
      const response = await axios.post(
        `${API}/delivery/update-status`,
        {
          id: id,
          userId: deliveryUserId,
          token: user.originToken,
        },
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );

      if (response) {
        window.location.reload();
      }
    } catch (error) {
      console.log("error", error);
    }
    setIsConfirming(false);
  };

  return (
    <div
      style={{
        overflow: "hidden",
      }}
    >
      <Navbar />

      <section
        className="profile"
        style={{
          backgroundColor: "#f4f5f7",
          marginTop: "0rem",
          overflow: "hidden",
        }}
      >
        {user ? (
          <div className="table-container">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Products</th>
                  <th scope="col">Amount(transport inclusive)</th>{" "}
                  <th scope="col">Delivery Agent</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                {order?.map((item) => (
                  <tr key={item?.order?._id}>
                    <td>
                      <ul style={{ marginLeft: "-15px" }}>
                        {item?.products?.map((item2) => (
                          <li key={item2?.product?._id}>
                            {item2?.product?.name} X{" "}
                            {item2?.cart?.numberOfItems} : {item2?.amount}
                          </li>
                        ))}
                      </ul>
                    </td>
                    <td>XAF {item?.order?.amount}</td>
                    <td>
                      {item?.deliveryUser ? (
                        <p>
                          {item?.deliveryUser?.firstname}{" "}
                          {item?.deliveryUser?.lastname}
                        </p>
                      ) : (
                        "__"
                      )}
                    </td>
                    <td>
                      {item?.delivery?.status === "PENDING" ? (
                        <button
                          type="button"
                          className={`btn btn-warning`}
                          style={{ color: "white" }}
                          disabled={isConfirming}
                          onClick={() =>
                            confirmDelivery(
                              item?.delivery?._id,
                              item?.deliveryUser?._id
                            )
                          }
                        >
                          Confirm delivery
                        </button>
                      ) : (
                        <button type="button" className={`btn btn-success`}>
                          Confirmed
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col col-lg-6 mb-4 mb-lg-0">
                <div className="card mb-3" style={{ borderRadius: ".5rem" }}>
                  <div className="row g-0">
                    <div className="col-md-8">
                      <div className="card-body p-4">
                        <h5
                          style={{
                            marginBottom: "1rem",
                          }}
                        >
                          No user found
                        </h5>
                        <Link to="/signin">
                          <button
                            className="btn"
                            style={{
                              width: "8rem",
                              padding: ".3rem rem",
                              background: "green",
                              color: "white",
                              height: "2.5rem",
                            }}
                          >
                            <b
                              style={{
                                color: "white",
                              }}
                            >
                              SignIn
                            </b>
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>

      <Footer />
    </div>
  );
};

export default MyOrders;
