import React from "react";
import "../App.css";
import AnchorLink from "react-anchor-link-smooth-scroll";
import myImage from "../images/grabit.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="container-fluid foo">
        <div className="row ">
          <div className="col-sm-5">
            <h4 className="ithq">
              <b>What Is GrabIt</b>
            </h4>
            <div className="line2 zoom-out"></div>
            <p className="it">
              GrabIT is an online marketplace aimed at reducing the stress
              involved in buying & selling of products by connecting shop
              owners/Business People with future buyers.
            </p>

            <div className="imgg logo zoom">
              <img src={myImage} />
            </div>
          </div>

          <div className="col-sm-4">
            <h4 className="ithq">
              <b>Quick Links</b>
            </h4>
            <div className="line2 zoom-out"></div>
            <ul className="navbar-nav ithl">
              <li className="nav-item active">
                <a className="nav-link" href="/register">
                  {" "}
                  Register a Shop <span className="sr-only"></span>
                </a>
              </li>
              <li className="nav-item active">
                <a className="nav-link" href="/create">
                  {" "}
                  Create a Shop <span className="sr-only"></span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Start A Premium Search
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="/about">
                  About Us
                </a>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/profile">
                  My Account
                </Link>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#contact">
                  Contact Us
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="https://delivery.grabit.sale"
                  target="_blank"
                  className="nav-link"
                  rel="noreferrer"
                >
                  Become a delivery agent
                </a>
              </li>
            </ul>
          </div>

          <div className="col-sm-3">
            <h4 className="ithq">
              <b>Areas Covered</b>
            </h4>
            <div className="line2 zoom-out"></div>
            <ul className="navbar-nav it">
              <li className="nav-item active">
                <a className="nav-link" href="#">
                  {" "}
                  Buea <span className="sr-only"></span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Limbe
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Douala
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Yaounde
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Bafoussam
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Kumba
                </a>
              </li>
            </ul>
          </div>
        </div>
        <p className="fot">
          <b>GrabIT, Buy And Sell Products Without The Stress</b>
        </p>
        <p className="fot">
          <Link to="https://www.facebook.com/profile.php?id=61558529822396&mibextid=kFxxJD">
            <button className=" icons fa fa-facebook-official"></button>
          </Link>
          <Link to="https://x.com/Grabitshopping?s=09">
            <button className="icons  fa fa-close"></button>
          </Link>
          <Link to="https://www.instagram.com/grabitsale1?igsh=NzU1c3M1bzZoNXRl">
            <button className="icons fa fa-instagram"></button>
          </Link>
          <Link to="https://www.tiktok.com/@grabit92?_t=8lbqfHKZVob&_r=1">
            <button className="icons	fa fa-tumblr-square"></button>
          </Link>
        </p>
      </div>

      <div className="row bgc">
        <p className="bottom">
          <b>GrabIT cCopyright 2024. All Rights Reserved.</b>{" "}
        </p>
      </div>
    </>
  );
};
export default Footer;
