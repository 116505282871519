import React from "react";
import '../App.css';
import Navbar from "../Home";
import RegisterShop from "./regshop";
import Footer from "../Home/footer";

  const ShopRegistration = () => {
  
 return ( 
    <>

    <Navbar/>
    <RegisterShop/>
    <Footer/>

   </>
 )
 
} 

export default ShopRegistration; 