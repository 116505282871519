import Navbar from "../Home";
import Footer from "./footer";
import LoginForm from "./login";

const Signin = () => { 
  
  return ( 
   <>
   <Navbar/>
   <LoginForm/>
   <Footer/>

   </>
  ); 
 } 

export default Signin;