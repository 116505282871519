import React, { useState, useEffect } from "react";
import "../App.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { API } from "../config";
import myImage from "../images/imagessp.jfif";
import img1 from "../images/plan.jfif";
import img2 from "../images/business.jfif";

const Cont2 = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [show, setShow] = useState(false);

  const images = [myImage, img1, img2];

  useEffect(() => {
    const listSubs = async () => {
      try {
        const subs = await axios.get(`${API}/subscription/subscription/list`);
        setSubscriptions(subs.data.subscriptions);

        let currentUser = localStorage.getItem("user");
        if (currentUser) {
          currentUser = JSON.parse(currentUser);

          let subVal;
          try {
            await axios.get(`${API}/subscription/active/${currentUser._id}`);
            subVal = true;
          } catch (error) {
            subVal = false;
          }
          console.log("sub.data.success", subVal);
          if (currentUser && currentUser.role === "seller" && !subVal) {
            setShow(true);
          }
        }
      } catch (err) {
        // console.log("cat", err);
      }
    };
    listSubs();
  }, []);

  return (
    <>
      <div className="container-fluid start">
        <h2 className="h2 get heada">
          <b>LET'S GET STARTED</b>
        </h2>
        <div className="line zoom-out"></div>
        <p className="para">
          Choose A Subscription Plan to Create An Online Shop & Sell products
        </p>

        <div className="row rowstyle margina">
          {subscriptions?.map((subscription, index) => (
            <div className="col-sm-4 colstyle2">
              <div className="card zoom-out">
                <h3 className="h3 h3style">{subscription.name}</h3>
                <img className="imgwg zoom" src={images[index]} alt="" />
                <p className="getps">{subscription.description}</p>
                <div>
                  <Link to={`/plan/${subscription._id}`}>
                    <button className="btn btn-success colr btnget">
                      <b>Subscribe</b>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          ))}

          {/* <div className="col-sm-4 colstyle2">
        <div className="card zoom-out">
          <h3 className="h3 h3style">Starter Plan</h3>
          <img className="imgwg zoom" src={myImage} />
          <p className="getps">
            Subscribe to our starter plan to register your shop on our list.
          </p>
          <div>
            <Link to="../plan">
              <button className="btn btn-success colr btnget">
                <b>Subscribe</b>
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div className="col-sm-4 colstyle2 zoom-out">
        <div className="card">
          <h3 className="h3style"> Business Plan</h3>
          <img className="imgwg zoom" src={img1} />
          <p className="getps">
            Subscribe to our business plan to register your shop on our list.{" "}
          </p>
          <Link to="../plan2">
            <button className="btn btn-success colr btnget">
              <b>Subscribe</b>
            </button>
          </Link>
        </div>
      </div>

      <div className="col-sm-4 colstyle2 colside2">
        <div className="card zoom-out">
          <h3 className="h3style">Premium Plan</h3>
          <img className="imgwg imtr zoom" src={img2} />
          <p className="getps">
            Subscribe to our premium plan to register your shop on our list.{" "}
          </p>
          <Link to="../plan3">
            <button className="btn btn-success colr btnget">
              <b>Subscribe</b>
            </button>
          </Link>
        </div>
      </div> */}
        </div>
      </div>
    </>
  );
};

export default Cont2;
