import React from "react";
import "../App.css";
import SignInSingUp from "../Home/singin";
const Vision = () => {
  return (
    <div className="container-fluid sign bg-primary heig">
      <div className="sign1">
        <SignInSingUp />
      </div>
      <h2 className="h2 coa">
        <b>ABOUT GRABIT </b>
      </h2>
      <div className="line1 zoom-out"></div>
    </div>
  );
};
export default Vision;
