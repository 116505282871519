import React from 'react';
import '../App.css';
import { Link } from 'react-router-dom';


function Secondary() {
    return (
      <div className='container-fluid sign'>
<h2 className="h2 coa"><b>USE THE GrabIT MOBILE APP </b></h2>
<div className="line1 zoom-out"></div>
<div className='row rowsecondary'>
    <div className='col-sm-5 colsecondary'>
    
            <h3 className='h3 seco'> Purchase A Product</h3>
            <p className='act'>(1).  Tell GrabIT the type of product you want(start a search)</p>
            <p className='act'>(2).   We instantly find your desired products and get them delivered to you when a purchase order has been validated</p>
            <p className='act'>(3).   Safely and Securely send orders and receive ordered products</p>
       
            
    </div>

    <div className='col-sm-5 colsecondary'>
   
            <h3 className='h3 seco'> Get An Online Store</h3>
            <p className='act'> (1).  Get a registered shop on the GrabIT marketplace</p>
            <p className='act'>(2).   Start posting and selling products</p>
            <p className='act'>(3).   Safely and Securely post products and receive purchase alerts</p>
       
    </div>
</div>
<div className='row'>
<div className="regbuts text-center">
    <Link to = ''><button className="register3 btn btn-success ms-auto" type="submit"><b><small> GET IT ON </small>Google Play</b></button></Link>
<Link to = ''><button className="register4 btn btn-success" type="submit"><b><small> DOWNLOAD ON </small>App Store</b></button></Link>
</div>
</div>
      </div>
           


    );
};
export default Secondary;
