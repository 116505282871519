import React from "react";
import "../App.css";

function Find() {
  return (
    <div className="container-fluid premium1">
      <h3 className="h3 fdp">
        <b>FIND A PRODUCT!</b>
      </h3>
      <div className="line3 zoom-out"></div>

      <div className="row">
        <div className="col-sm-9">
          <form className="searchw input-group" action="">
            <input
              type="text"
              className="form-control"
              placeholder="What are you looking for......?"
            />
            <div className="input-group-append">
              <button className="btn btn-success" type="button">
                <i className="fa fa-search"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default Find;
