import React from "react";
import "../App.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from ".";
import Footer from "./footer";
import axios from "axios";
import { API } from "../config";
function Forgot() {
  const [formData, setFormData] = useState({
    email: "",
  });

  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    // Validate email
    if (!formData.email) {
      newErrors.email = "Email is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (validateForm()) {
      try {
        // Form is valid, you can submit or process the data here
        const response = await axios.post(`${API}/user/forgotpassword`, {
          email: formData.email,
        });
        console.log("response", response);
        setSuccess(response.data.message);
        setSubmitted(true); // Set a submitted flag
      } catch (error) {
        console.log("error", error);
        setError(error.response.data.message);
      }
    } else {
      // Form is not valid, display error messages
    }
    setIsSubmitting(false);
  };

  const isFormValid = Object.keys(errors).length === 0 && !isSubmitting;

  return (
    <>
      <Navbar />
      <div className="container-fluid sign">
        <div className="App1 mx-auto">
          <form onSubmit={handleSubmit}>
            <div className="formb">
              <fieldset>
                <h2 className="forma h2">
                  <b>Forgot Password</b>
                </h2>
                <div className="line zoom-out"></div>
                <div className="Field">
                  <label>
                    Email<sup>*</sup>{" "}
                  </label>
                  <input
                    type="text"
                    name="email"
                    placeholder="Email address"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                  {errors.email && <div className="error">{errors.email}</div>}
                </div>

                {success && (
                  <div style={{ color: "green", paddingBottom: ".1rem" }}>
                    <p>{success}</p>
                  </div>
                )}

                {error && (
                  <div style={{ color: "red", paddingBottom: ".1rem" }}>
                    <p>{error}</p>
                  </div>
                )}
                <button
                  type="submit"
                  disabled={!isFormValid}
                  className="formbuts"
                >
                  Submit
                </button>
              </fieldset>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Forgot;
