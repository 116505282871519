import React, { useState, useEffect } from "react";
import "../App.css";
import Footer from "./footer";
import Navbar from ".";
import axios from "axios";
import { API } from "../config";
const Cart = () => {
  const deliveryAmount = 1500;
  const [carts, setCarts] = useState([]);
  const [location, setLocation] = useState("");
  const [region, setRegion] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  let user = localStorage.getItem("user");
  if (user) {
    user = JSON.parse(user);
  }
  const regions = [
    "Adamawa Region",
    "Centre Region",
    "East Region",
    "Far North Region",
    "Littoral Region",
    "North Region",
    "Northwest Region",
    "South Region",
    "Southwest Region",
    "West Region",
  ];

  useEffect(() => {
    const listCarts = async () => {
      try {
        if (!user) {
          return;
        }
        const cart = await axios.get(`${API}/cart/${user._id}`);
        console.log("carts: ", cart);
        setCarts(cart.data.carts);
        setTotalAmount(cart.data.totalAmount);
      } catch (err) {
        console.log("cart", err);
      }
    };
    listCarts();
  }, []);

  const deleteCart = async (cart) => {
    try {
      if (!user) {
        window.location.href = "/signin";
      }
      const response = await axios.post(
        `${API}/cart/delete`,
        {
          userId: user._id,
          id: cart._id,
          token: user.originToken,
        },
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      console.log("carts: ", response);
      setCarts(response.data.carts);
      setTotalAmount(response.data.totalAmount);
    } catch (error) {
      console.log("error", error);
    }
  };

  const updateCart = async (cart, value) => {
    try {
      if (!user) {
        window.location.href = "/signin";
      }

      if (cart.numberOfItems === 1 && value === "minus") {
        return;
      }

      let numberOfItems = cart.numberOfItems;
      if (value === "minus") {
        numberOfItems -= 1;
      } else {
        numberOfItems += 1;
      }

      const response = await axios.post(
        `${API}/cart/update`,
        {
          userId: user._id,
          id: cart._id,
          token: user.originToken,
          numberOfItems,
        },
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      console.log("carts: ", response);
      setCarts(response.data.carts);
      setTotalAmount(response.data.totalAmount);
    } catch (error) {
      console.log("error", error);
    }
  };

  const initiatePayment = async () => {
    if (!region) {
      alert("Please select your receiving region");
      return;
    }
    if (!location) {
      alert("Please enter your receiving location");
      return;
    }
    setIsSubmitting(true);
    try {
      let user = localStorage.getItem("user");
      if (!user) {
        window.location.href = "/signin";
      }
      user = JSON.parse(user);
      const response = await axios.post(
        `${API}/payment/init-multi-prod`,
        {
          userId: user._id,
          message: `You are about to purchase a list of items found on your Cart`,
          token: user.originToken,
          location,
          region,
        },
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      console.log("response", response);
      window.open(response.data.response.link);
    } catch (error) {
      console.log("error", error);
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid sign">
        <div className="cartco">
          <div className="card ">
            <div className="row">
              <div className="col-md-8 cart">
                <div className="title">
                  <div className="row">
                    <div className="col">
                      <h4>
                        <b>My Cart</b>
                      </h4>

                      <p>{carts.length} Items Available In Cart</p>
                    </div>
                    <div className="col align-self-center text-right text-muted">
                      {carts.length} items
                    </div>
                  </div>
                </div>

                {carts?.map((cart) => (
                  <div key={cart._id} className="row border-top border-bottom">
                    <div className="row main align-items-center">
                      <div className="col-2">
                        <img
                          className="img-fluid"
                          src={`${API}/images/${cart.productImage[0]}`}
                          alt=""
                        ></img>
                      </div>
                      <div className="col">
                        <div className="row text-muted">{cart.productName}</div>
                      </div>
                      <div className="col">
                        <button
                          className="alinkc"
                          onClick={() => updateCart(cart, "minus")}
                        >
                          -
                        </button>
                        <span className="alinkc border">
                          {cart.numberOfItems}
                        </span>
                        <button
                          className="alinkc"
                          onClick={() => updateCart(cart, "plus")}
                        >
                          +
                        </button>
                      </div>
                      <div className="col">
                        {cart.totalAmount}
                        <button
                          className="close"
                          style={{ marginLeft: "1rem" }}
                          onClick={() => deleteCart(cart)}
                        >
                          &#10005;
                        </button>
                      </div>
                    </div>
                  </div>
                ))}

                {/* <div className="row">
                  <div className="row main align-items-center">
                    <div className="col-2">
                      <img className="img-fluid" src={img2}></img>
                    </div>
                    <div className="col">
                      <div className="row text-muted">Iphone13</div>
                    </div>
                    <div className="col">
                      <a className="alinkc" href="#">
                        -
                      </a>
                      <a className="alinkc border" href="#">
                        1
                      </a>
                      <a className="alinkc" href="#">
                        +
                      </a>
                    </div>
                    <div className="col">
                      400,000Frs <span className="close">&#10005;</span>
                    </div>
                  </div>
                </div>
                <div className="row border-top border-bottom">
                  <div className="row main align-items-center">
                    <div className="col-2">
                      <img className="img-fluid" src={img4}></img>
                    </div>
                    <div className="col">
                      <div className="row text-muted">Shirt</div>
                    </div>
                    <div className="col">
                      <a className="alinkc" href="#">
                        -
                      </a>
                      <a className="alinkc border" href="#">
                        1
                      </a>
                      <a className="alinkc" href="#">
                        +
                      </a>
                    </div>
                    <div className="col">
                      5000Frs <span className="close">&#10005;</span>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="col-md-4 summary">
                <div>
                  <h5>
                    <b>Summary</b>
                  </h5>
                </div>
                <hr></hr>
                <div className="row">
                  <div className="col styleI">ITEMS {carts.length}</div>
                  <div className="col text-right">{totalAmount}Frs</div>
                </div>

                <div className="Field" style={{ paddingTop: "1rem" }}>
                  <label>
                    Location <sup>*</sup>
                  </label>
                  <input
                    value={location}
                    type="text"
                    onChange={(e) => {
                      setLocation(e.target.value);
                    }}
                    onBlur={(e) => {
                      setLocation(e.target.value);
                    }}
                    placeholder="Enter your delivery location"
                    style={{ marginBottom: ".8rem" }}
                  />

                  <label>
                    Select region <sup>*</sup>
                  </label>
                  <select
                    value={region}
                    onChange={(e) => setRegion(e.target.value)}
                    className=""
                  >
                    {regions.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
                {/* <form className="fordelivery">
                  <p>Location</p>
                  <select className="selection">
                    <option className="text-muted">
                      Standard-Delivery- 2000Frs
                    </option>
                  </select>
                </form> */}
                <div className="row stylep">
                  <div className="col">TOTAL PRICE</div>
                  {totalAmount ? (
                    <div className="col text-right">
                      {totalAmount + deliveryAmount}Frs
                    </div>
                  ) : (
                    <div className="col text-right">{totalAmount}Frs</div>
                  )}
                </div>
                {totalAmount !== 0 && (
                  <div className="row stylep">
                    <div className="col">
                      NOTE: delivery fee is {deliveryAmount}frs
                    </div>
                  </div>
                )}
                <button
                  className="btn btn-success checki"
                  disabled={isSubmitting}
                  onClick={() => initiatePayment()}
                >
                  CHECKOUT
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Cart;
