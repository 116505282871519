import React, { useRef, useEffect, useCallback, useState } from "react";
import Webcam from "react-webcam";
import { v4 as uuidv4 } from "uuid";

const WebcamCapture = ({ handleImage }) => {
  const [videoConstraints, setVideoConstraints] = useState({
    facingMode: { exact: "environment" },
  });
  const webcamRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    // const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    // if (isMobile) {
    //   setVideoConstraints({
    //     facingMode: "user",
    //   });
    // }
  }, []);

  const capture = useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImageSrc(imageSrc);
    const image = await fetchImage(imageSrc);
    handleImage(image);
  }, [webcamRef]);

  const fetchImage = async (imageUrl) => {
    const uuid = uuidv4();
    let response;
    response = await fetch(imageUrl);
    response = await response.blob();
    response = new File([response], `docs_${uuid}.png`);
    return response;
  };

  return (
    <div>
      {!imageSrc && (
        <>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            class="col-lg-6"
            videoConstraints={videoConstraints}
          />
          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button className="btn btn-success" onClick={capture}>
              Snap document
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default WebcamCapture;
