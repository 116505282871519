import React from "react";
import "../App.css";
import { Link } from "react-router-dom";
import SignInSingUp from "../Home/singin";

const Shopc1 = () => {
  return (
    <div className="container-fluid sign bg-primary ">
      <div className="sign1">
        <SignInSingUp />
      </div>
      <h2 className="h2 coa">
        <b>SHOPS </b>
      </h2>
      <div className="line1 zoom-out"></div>
      <p className="pb">
        Get connected instantly and directly with prospective shop owners,
        businessess and purchase your desired product. Register an
        existing/physical shop or Create an online Store
      </p>
      <div className="row">
        <div className="regbuts text-center">
          <Link to="/register">
            <button className="register1 btn btn-success" type="submit">
              <b>Register Shop</b>
            </button>
          </Link>
          <Link to="/create">
            <button className="register2 btn btn-success" type="submit">
              <b>Create Shop</b>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default Shopc1;
