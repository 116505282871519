import React, { useEffect } from "react";
import "../App.css";
import Products from "../Home/products";
import Testimonials from "../Home/testimonial";
import Buyc1 from "./buycontainer";
import Navbar from "../Home";
import Footer from "../Home/footer";
import Promo from "../Home/promo";
import Find from "./find";
import Secondary from "../Home/secondary";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../redux-store/feature/product/productSlice";
import PromotionProducts from "../Home/promotion-products";

const Buy = () => {
  const products = useSelector((state) => state.product.promotionProducts);
  const dispatch = useDispatch();
  useEffect(() => {
    const listProducts = async () => {
      dispatch(getProducts());
    };
    listProducts();
  }, [dispatch]);
  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <Navbar />
        <Buyc1 />
        <Products />
        {products?.length !== 0 && <PromotionProducts />}
        {/* <Promo /> */}
        <Testimonials />
        <div className="back">
          <br></br>
          <br></br>
        </div>
        <Secondary />
        <div className="back">
          <br></br>
          <br></br>
        </div>
        <Find />
        <Footer />
      </div>
    </>
  );
};
export default Buy;
