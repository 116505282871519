import React, { useState, useEffect } from "react";
import "../App.css";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../redux-store/feature/product/productSlice";
import axios from "axios";
import { API } from "../config";
import { Link } from "react-router-dom";

const Product = ({ product }) => {
  const [cart, setCart] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [delProduct, setDelProduct] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const listCartByUser = async () => {
    try {
      let user = localStorage.getItem("user");
      if (!user) {
        window.location.href = "/signin";
      }
      user = JSON.parse(user);
      const response = await axios.post(`${API}/cart/cart/user`, {
        userId: user._id,
        productId: product._id,
      });
      console.log("response", response);
      if (response.data.cart) {
        setCart(response.data.cart.numberOfItems);
      }
    } catch (error) {
      console.log("error carts", error);
    }
  };

  listCartByUser();

  const initiatePayment = async (product) => {
    setIsSubmitting(true);
    const crt = await addToCart(product._id);
    if (crt) {
      window.location.href = `/cart`;
    }

    setIsSubmitting(false);
  };

  const addToCart = async (productId) => {
    try {
      let user = localStorage.getItem("user");
      if (!user) {
        window.location.href = "/signin";
      }
      user = JSON.parse(user);
      const response = await axios.post(
        `${API}/cart/create`,
        {
          userId: user._id,
          productId,
          numberOfItems: 1,
          status: "PENDING",
          token: user.originToken,
        },
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      console.log("response", response);
      await listCartByUser();
      return true;
    } catch (error) {
      console.log("error", error);
      return false;
    }
  };

  const deleteProduct = async () => {
    try {
      let user = localStorage.getItem("user");
      if (!user) {
        alert("Please signin to your account");
      } else {
        setIsDeleting(true);
      }
      user = JSON.parse(user);
      const response = await axios.post(
        `${API}/product/delete`,
        {
          userId: user._id,
          productId: delProduct._id,
          token: user.originToken,
        },
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      window.location.reload();
    } catch (error) {
      console.log("error", error);
    }
    setIsDeleting(false);
  };

  return (
    <div className="col-sm-3 colstyle">
      <div className="card zoom-out">
        <a href={`/product/${product._id}`}>
          <img
            className="imgw zoom object-fit-cover"
            src={`${API}/images/${product.image[0]}`}
            alt=""
          />
        </a>

        <div className="rowst link-offset-2 link-underline link-underline-opacity-0">
          <a
            href={`/product/${product._id}`}
            className="row link-offset-2 link-underline link-underline-opacity-0"
          >
            <div className="col">
              <h5 className="lef fontsi">
                <b>{product.name}</b>
              </h5>
            </div>

            {product?.promotionPrice && product?.promotionPrice !== 0 ? (
              <div className="col">
                <h5 className="rig fontsi prom">
                  <p
                    className="text-decoration-line-through"
                    style={{ color: "gray" }}
                  >
                    {product.price}Frs
                  </p>
                </h5>
                <h5 className="rig fontsi prom" style={{ marginTop: "-1rem" }}>
                  <b>{product.promotionPrice}Frs</b>
                </h5>
              </div>
            ) : (
              <div className="col">
                <h5 className="rig fontsi prom">
                  <b>{product.price}Frs</b>
                </h5>
              </div>
            )}
          </a>

          <div
            className="butspad"
            style={{ display: "flex", justifyContent: "end" }}
          >
            <div>
              <a
                href={`edit/${product._id}`}
                className="btn btn-success colr"
                onClick={() => initiatePayment(product)}
                disabled={isSubmitting}
              >
                <b>Edit</b>
              </a>
              <button
                type="button"
                className="btn btn-danger ms-1"
                data-toggle="modal"
                data-target="#exampleModalCenter"
                onClick={() => setDelProduct(product)}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Delete Product
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Are you sure you want to delete this product?</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                onClick={() => deleteProduct()}
                type="button"
                className="btn btn-danger"
                disabled={isDeleting}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const UserProducts = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    const getProducts = async () => {
      try {
        let user = localStorage.getItem("user");
        if (!user) {
          return;
        }
        user = JSON.parse(user);
        const result = await axios.get(`${API}/store/user/${user._id}`);
        if (result.data.store[0].length !== 0) {
          const prods = await axios.get(
            `${API}/product/store/${result.data.store[0]._id}`
          );
          setProducts(prods.data.products);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    getProducts();
  }, [dispatch]);

  return (
    <>
      <div className="sign bg-primary ">
        <div className="row" style={{ paddingTop: "8rem" }}>
          <div className="regbuts text-center">
            <b
              style={{
                color: "white",
                fontSize: "2rem",
                marginTop: "-2rem",
              }}
            >
              Your Porducts
            </b>
          </div>
        </div>
        <div
          className="row"
          style={{ marginTop: "-5rem", paddingBottom: "3rem" }}
        >
          <div className="regbuts text-center">
            <Link
              to="/post"
              style={{
                width: "13rem",
                padding: ".3rem rem",
                background: "green",
                color: "white",
              }}
            >
              <button className="btn">
                <b
                  style={{
                    color: "white",
                  }}
                >
                  Post new products
                </b>
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div
        className=""
        style={{
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            background: "green",
            height: "3rem",
          }}
        ></div>

        <div className="container-fluid mid">
          <div className="row rowstyle">
            {products?.map((product) => (
              <Product key={product._id} product={product} />
            ))}

            {products?.length === 0 && (
              <div
                style={{
                  width: "100vw",
                  height: "3rem",
                  textAlign: "center",
                  paddingTop: "2rem",
                }}
              >
                {loading ? (
                  <p>Fetching products...</p>
                ) : (
                  <p>Ooops no products found</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProducts;
