import React from "react";
import "../App.css";
import { Link } from "react-router-dom";

function Cont3() {
  return (
    <div className="container-fluid premium">
      <h3 className="h3 con"> DIDN'T FIND YOUR DESIRED PRODUCT YET? </h3>
      <div className="line1 zoom-out"></div>
      <p className="con">
        Create a product request(alert) and get connectted with multiple shops
        to assist you out
      </p>

      <div className="text-center">
        <Link to="/more">
          {" "}
          <button className="but btn btn-success">
            <b>START A PREMIUM SEARCH</b>{" "}
          </button>
        </Link>
      </div>
    </div>
  );
}
export default Cont3;
