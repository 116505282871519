import React, { useState, useEffect } from "react";
import Footer from "../Home/footer";
import Navbar from "../Home";
import { Link } from "react-router-dom";
import axios from "axios";
import { API } from "../config";
const VerifyDoc = () => {
  const [user, setUser] = useState(null);
  const [store, setStore] = useState(null);
  useEffect(() => {
    const getUser = async () => {
      try {
        let user = localStorage.getItem("user");
        if (!user) {
          window.location.href = "/signin";
        } else {
          setUser(JSON.parse(user));
          const result = await axios.get(
            `${API}/store/user/${JSON.parse(user)._id}`
          );
          setStore(result.data.store[0]);
        }
      } catch (err) {}
    };
    getUser();
  }, []);

  return (
    <div
      style={{
        overflow: "hidden",
      }}
    >
      <Navbar />

      <section
        className="profile"
        style={{
          backgroundColor: "#f4f5f7",
          marginTop: "0rem",
          overflow: "hidden",
        }}
      >
        {user ? (
          <div className="h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col col-lg-6 mb-4 mb-lg-0">
                <div className="card mb-3" style={{ borderRadius: ".5rem" }}>
                  <div className="row g-0">
                    <div className="col-12">
                      <div className="card-body p-4">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {/* Identification  */}
                          <h6>Verify Document</h6>

                          {store?.identificationDocumentVeried ? (
                            <button className="btn btn-success">
                              Verified
                            </button>
                          ) : (
                            <a href="/verify-doc" className="btn btn-success">
                              verify
                            </a>
                          )}
                        </div>

                        {/* {store && store?.storeType !== "Online Store" && (
                          <div style={{ marginTop: "1rem" }}>
                            <hr className="mt-0 mb-4" />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "1rem",
                              }}
                            >
                              <h6>Verify Legal Document</h6>

                              {store?.legalDocumentVeried ? (
                                <button className="btn btn-success">
                                  Verified
                                </button>
                              ) : (
                                <a
                                  href="/verify-doc"
                                  className="btn btn-success"
                                >
                                  verify
                                </a>
                              )}
                            </div>
                          </div>
                        )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col col-lg-6 mb-4 mb-lg-0">
                <div className="card mb-3" style={{ borderRadius: ".5rem" }}>
                  <div className="row g-0">
                    <div className="col-md-8">
                      <div className="card-body p-4">
                        <h5
                          style={{
                            marginBottom: "1rem",
                          }}
                        >
                          No user found
                        </h5>
                        <Link to="/signin">
                          <button
                            className="btn"
                            style={{
                              width: "8rem",
                              padding: ".3rem rem",
                              background: "green",
                              color: "white",
                              height: "2.5rem",
                            }}
                          >
                            <b
                              style={{
                                color: "white",
                              }}
                            >
                              SignIn
                            </b>
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>

      <Footer />
    </div>
  );
};

export default VerifyDoc;
