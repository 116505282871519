import axios from "axios";
import { API } from "../../../config";
const signUp = async (user) => {
  try {
    const createResult = await axios.post(`${API}/user/signup`, user);
    return { success: true, user: createResult };
  } catch (error) {
    console.log(error);
    return { success: false, error: error };
  }
};

const signIn = async (user) => {
  try {
    const createResult = await axios.post(`${API}/user/signin`, user);
    return { success: true, user: createResult };
  } catch (error) {
    console.log(error);
    return { success: false, error: error };
  }
};

const fetchUser = async (userId) => {
  axios
    .get(`${API}/user/signin/${userId}`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
};

const userService = {
  signUp,
  signIn,
  fetchUser,
};
export default userService;
