import React from "react";
import { useState, useEffect } from "react";
import "../App.css";
import myImage from "../images/grabit.png";
import { Link } from "react-router-dom";
import NewComponent from "../i18n/NewComponent";
import LanguageContext from "../i18n/LanguageContext";
import translations from "../i18n";

let frenchCodes = ["fr-FR", "fr-fr", "fr"];
let language = navigator.language || navigator.userLanguage;
if (!frenchCodes.includes(language)) {
  language = "en-US";
}

const Nav = () => {
  const [selectedWallet, setSelectedWallet] = useState("");
  const handleSelectChange = (event) => {
    setSelectedWallet(event.target.value);
  };
  const [lng, setLng] = useState(language);
  return (
    <ul className="navbar-nav">
      <Link to="/" className="nav-link navlin">
        <li className="nav-item active">
          <b>Home</b>
        </li>
      </Link>
      <Link to="../about" className="nav-link navlin">
        <li className="nav-item ">
          <b>About</b>
        </li>
      </Link>
      <Link to="../shop" className="nav-link navlin">
        <li className="nav-item">
          <b>Shop</b>
        </li>
      </Link>
      <Link to="../buy" className="nav-link navlin">
        <li className="nav-item">
          <b>Buy</b>
        </li>
      </Link>

      <li className="nav-item ">
        <div className="text-right">
          <select onChange={handleSelectChange}>
            <option value="english">
              <button
                className="nav-link btn btn-success lbut"
                onClick={() => setLng("en-US")}
              >
                <b>English</b>
              </button>
            </option>
            <option value="french">
              <button
                className="nav-link btn btn-success lbut"
                onClick={() => setLng("fr-FR")}
              >
                French
              </button>
            </option>
          </select>
        </div>
      </li>
    </ul>
  );
};

const Navbar = () => {
  const [windowWidth, setWindowWidth] = useState();
  const [show, setShow] = useState(false);
  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  return (
    <>
      <div className="container-fluid header">
        <div className="logo imga">
          <img src={myImage} />
        </div>
        <nav className="navbar navbar-expand-md">
          <button
            className="navbar-toggler"
            type="button"
            onClick={() => setShow(!show)}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {windowWidth > 767 ? (
            <div className="collapse navbar-collapse" id="navbarNav">
              <Nav />
            </div>
          ) : (
            <>
              {show ? (
                <div
                  style={{
                    position: "absolute",
                    right: "-1.5rem",
                    top: 70,
                    background: "white",
                    padding: "1.5rem",
                    zIndex: 100,
                  }}
                  className="navbar-collapse"
                  id="navbarNav"
                >
                  <Nav />
                </div>
              ) : (
                <div className="collapse navbar-collapse" id="navbarNav">
                  <Nav />
                </div>
              )}
            </>
          )}
        </nav>
      </div>
    </>
  );
};

export default Navbar;
