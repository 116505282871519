import React, { useState, useEffect } from "react";
import Navbar from ".";
import Footer from "./footer";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API } from "../config";
export default function Payment() {
  const [paymentLink, setPaymentLink] = useState("");
  const navigate = useNavigate();

  return (
    <>
      <Navbar />
      <div className="container-fluid ing sign">
        <div className="App1 fots">
          <div className="card hi">
            <div className="Field">
              <h3 className="h3 pur">Select Payment Method</h3>
              <div className="line1 zoom-out"></div>

              {/* <Link to="/momo"></Link> */}
              <a
                id="link"
                href={`${paymentLink}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Mobile Money
              </a>
              <a
                id="link"
                href={`${paymentLink}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Credit Card
              </a>

              {/* <Link to="/creditcard">
                <button className="signupin btn btn-success">
                  Credit Card
                </button>
              </Link> */}
              <Link>
                <button className="signupin btn btn-success">Paypal</button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
