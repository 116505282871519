import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import userService from "./userService";

const initialState = {
  user: null,
  isSigningUp: false,
  isSigningIn: false,
};

export const signUp = createAsyncThunk("user/signUp", async (user) => {
  return await userService.signUp(user);
});
export const signIn = createAsyncThunk("user/signIn", async (user) => {
  return await userService.signIn(user);
});
export const fetchUser = createAsyncThunk("user/fetchUser", async (userId) => {
  return await userService.fetchUser(userId);
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    reset: (state) => {
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signUp.pending, (state, action) => {
        console.log("pend", action);
        state.isSigningUp = true;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        console.log("ful", action);
        state.isSigningUp = false;
        state.user = action.payload;
      })
      .addCase(signUp.rejected, (state, action) => {
        console.log("actions", action);
        state.isSigningUp = false;
        state.user = null;
      });
  },
});

export default userSlice.reducer;
export const { reset } = userSlice.actions;
