import React, { useState, useEffect } from "react";
import "../App.css";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../redux-store/feature/product/productSlice";
import axios from "axios";
import { API } from "../config";
import { Link } from "react-router-dom";

const Product = ({ product }) => {
  const [cart, setCart] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const listCartByUser = async () => {
    try {
      let user = localStorage.getItem("user");
      if (!user) {
        // window.location.href = "/signin";
        return;
      }
      user = JSON.parse(user);
      const response = await axios.post(`${API}/cart/cart/user`, {
        userId: user._id,
        productId: product._id,
      });
      // console.log("response", response);
      if (response.data.cart) {
        setCart(response.data.cart.numberOfItems);
      }
    } catch (error) {
      console.log("error carts", error);
    }
  };

  listCartByUser();

  const initiatePayment = async (product) => {
    setIsSubmitting(true);
    const crt = await addToCart(product._id);
    if (crt) {
      window.location.href = `/cart`;
    }

    // try {
    //   let user = localStorage.getItem("user");
    //   if (!user) {
    //     window.location.href = "/signin";
    //   }
    //   user = JSON.parse(user);
    //   const response = await axios.post(
    //     `${API}/payment/init-single-prod`,
    //     {
    //       userId: user._id,
    //       productId: product._id,
    //       message: `You are about to purchase this product "${product.name}"`,
    //       token: user.originToken,
    //     },
    //     {
    //       headers: { Authorization: `Bearer ${user.token}` },
    //     }
    //   );
    //   console.log("response", response);
    //   // window.location.href = `${response.data.response.link}`;
    //   window.open(response.data.response.link);
    // } catch (error) {
    //   console.log("error", error);
    // }
    setIsSubmitting(false);
  };

  const addToCart = async (productId) => {
    try {
      let user = localStorage.getItem("user");
      if (!user) {
        window.location.href = "/signin";
      }
      user = JSON.parse(user);
      const response = await axios.post(
        `${API}/cart/create`,
        {
          userId: user._id,
          productId,
          numberOfItems: 1,
          status: "PENDING",
          token: user.originToken,
        },
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      console.log("response", response);
      await listCartByUser();
      return true;
    } catch (error) {
      console.log("error", error);
      return false;
    }
  };

  return (
    <div className="col-sm-3 colstyle">
      <div className="card zoom-out">
        <a href={`/product/${product._id}`}>
          <img
            className="imgw zoom object-fit-cover"
            src={`${API}/images/${product.image[0]}`}
            alt=""
          />
        </a>
        <div className="rowst">
          <a
            href={`/product/${product._id}`}
            className="row link-offset-2 link-underline link-underline-opacity-0"
          >
            <div className="col">
              <h5 className="lef fontsi">
                <b>{product.name}</b>
              </h5>
            </div>
            <div className="col">
              <h5 className="rig fontsi prom">
                <p
                  className="text-decoration-line-through"
                  style={{ color: "gray" }}
                >
                  {product.price}Frs
                </p>
              </h5>
              <h5 className="rig fontsi prom" style={{ marginTop: "-1rem" }}>
                <b>{product.promotionPrice}Frs</b>
              </h5>
            </div>
          </a>

          <div className="row" style={{ paddingTop: ".5rem" }}>
            <div className="col">
              <h5 className="lef fontsi">
                <Link to="/cart">View carts</Link>
              </h5>
            </div>
            <div className="col">
              <h5 className="rig fontsi prom">
                <b>{`Cart(${cart})`}</b>
              </h5>
            </div>
          </div>

          <div className="butspad">
            <button
              className="btn btn-success colr"
              onClick={() => initiatePayment(product)}
              disabled={isSubmitting}
            >
              <b>Buy</b>
            </button>
            <button
              className="btn btn-success colr carti"
              onClick={() => addToCart(product._id)}
            >
              <b>Add to Cart</b>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const PromotionProducts = () => {
  const products = useSelector((state) => state.product.promotionProducts);
  const dispatch = useDispatch();
  useEffect(() => {
    const listProducts = async () => {
      dispatch(getProducts());
    };
    listProducts();
  }, [dispatch]);

  return (
    <div
      className="container-fluid up "
      style={{
        paddingTop: "0px",
        marginTop: "-3rem",
      }}
    >
      <div className="three pb-3">
        <button className="most2 btn btn-success"></button>
        <button className="most1 btn btn-success">
          <b>Promotion products</b>
        </button>
        <button className="most2 btn btn-success"></button>
      </div>

      <div className="container-fluid mid">
        <div className="row rowstyle">
          {products?.map((product) => (
            <Product key={product._id} product={product} />
          ))}

          {products?.length === 0 && (
            <div
              style={{
                width: "100vw",
                height: "3rem",
                textAlign: "center",
                paddingTop: "2rem",
              }}
            >
              <p>Ooops no products found</p>
            </div>
          )}
        </div>
        {/* <div className="text-center">
          <Link to="/more">
            {" "}
            <button className="seac btn btn-success">
              <b>SEE MORE</b>{" "}
            </button>{" "}
          </Link>{" "}
        </div> */}
      </div>
    </div>
  );
};
export default PromotionProducts;
