import React from 'react';
import '../App.css';
import { Link } from 'react-router-dom';


function Own() {
    return (
        <div className='container-fluid premium'>
        
                <h3 className='h3 con'> LOOKING FORWARD TO OWNING A STORE? </h3>
                <div className='line1 zoom-out'></div>
                <p className="pb">Register an existing/physical shop or Create an online Store to sell products</p>
                <div className="regbuts">
                <Link to = '/register'><button className="registerr btn btn-success" type="submit"><b>Register Shop</b></button></Link>
<Link to='/create'><button className="registerc btn btn-success" type="submit"><b>Create Shop</b></button></Link>
</div>
            </div>


    );
};
export default Own;
