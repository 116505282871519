import { configureStore } from "@reduxjs/toolkit";
import { userSlice } from "./feature/user/userSlice";
import { productSlice } from "./feature/product/productSlice";
export default configureStore({
  reducer: {
    user: userSlice.reducer,
    product: productSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
