import React, { useState, useEffect } from "react";
import "../App.css";
import Footer from "../Home/footer";
import Navbar from "../Home";
import axios from "axios";
import { API } from "../config";
function Post() {
  const [productImage, setProductImage] = useState();
  const [isAdding, setIsAdding] = useState(false);
  const [newCat, setNewCat] = useState("");
  const [itemName, setItemName] = useState("");
  const [price, setprice] = useState("");
  const [promotionPrice, setPromotionPrice] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState("");
  useEffect(() => {
    const listCategory = async () => {
      try {
        const cat = await axios.get(`${API}/category/category/list`);
        if (cat) {
          setCategories(cat.data.categorys);
        }
      } catch (err) {
        console.log("cat", err);
      }
    };
    listCategory();
    // return () => {
    //   listCategory();
    // };
  }, []);

  const handleFile = (e) => {
    const file = e.target.files;
    if (file.length > 4) {
      alert("You can only select a maximium of 4 images");
      window.location.reload();
    } else {
      setProductImage(file);
    }
  };

  const getIsFormValid = () => {
    return productImage && itemName && price && categoryId && !isSubmitting;
  };

  const clearForm = () => {
    setProductImage("");
    setItemName("");
    setCategoryId("");
    setprice("");
    setPromotionPrice("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (categoryId === "category") {
      return setError({ category: "Please select product category" });
    } else {
      setIsSubmitting(true);
      setError("");
    }
    let user = localStorage.getItem("user");
    if (!user) {
      window.location.href = "/signin";
    }
    user = JSON.parse(user);

    // sending post request to upload file
    const img = [];
    for (const file of productImage) {
      const formData = new FormData();
      formData.append("myFile", file);
      axios
        .post(`${API}/upload/image`, formData, {
          headers: {
            "content-tupe": "multipart/form-data",
          },
        })
        .then((res) => {})
        .catch((err) => {});
      img.push(file.name);
    }

    // get store Id
    try {
      const result = await axios.get(`${API}/store/user/${user._id}`);
      const product = {
        name: itemName,
        image: img,
        price,
        promotionPrice,
        categoryId,
        storeId: result.data.store[0]._id,
        token: user.originToken,
        userId: user._id,
      };

      console.log("result", result);
      const createProduct = await axios.post(`${API}/product/create`, product, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      if (createProduct.data.success) {
        setSuccess("Product created successfully");
      } else {
        setError(createProduct.error.response.data.errors);
      }

      window.location.href = "/shop";
    } catch (error) {
      console.log("error", error);
      setError(error.response.data.errors);
    }
    setIsSubmitting(false);
    clearForm();
  };
  const AddCategory = async () => {
    let user = localStorage.getItem("user");
    if (!newCat) {
      document.getElementById("closeModal").click();
      return setError({ category: "Please enter category name" });
    } else {
      if (!user) {
        document.getElementById("closeModal").click();
        return setError({
          category: "You are not authenticated to add a category",
        });
      }
      user = JSON.parse(user);
      setIsAdding(true);
      setError("");
    }
    try {
      await axios.post(
        `${API}/category/create/`,
        { name: newCat, token: user.originToken, userId: user._id },
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      window.location.reload();
    } catch (error) {
      console.log("error", error);
      setError(error.response.data.errors);
    }
    setIsSubmitting(false);
    setIsAdding(false);
    clearForm();
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid ing sign">
        <div className="App5">
          <form onSubmit={handleSubmit}>
            <div className="formb">
              <fieldset>
                <h2 className="forma h2">
                  <b>Post A Product</b>
                </h2>
                <div className="line"></div>
                <div className="Field">
                  <label className="labelsc"> Product Image</label>
                  <input
                    type="file"
                    onChange={(e) => {
                      handleFile(e);
                    }}
                    placeholder="Product Image"
                    multiple
                  />
                </div>
                <div className="Field">
                  <label className="labelsc"> Product Item</label>
                  <input
                    value={itemName}
                    onChange={(e) => {
                      setItemName(e.target.value);
                    }}
                    placeholder="Item Name"
                  />
                </div>
                <div className="Field">
                  <label className="labelsc"> price</label>
                  <input
                    value={price}
                    onChange={(e) => {
                      setprice(e.target.value);
                    }}
                    placeholder="price"
                  />
                </div>
                <div className="Field">
                  <label className="labelsc">Promotion price</label>
                  <input
                    value={promotionPrice}
                    onChange={(e) => {
                      setPromotionPrice(e.target.value);
                    }}
                    placeholder="price"
                  />
                </div>
                <div className="Field">
                  <label>Category</label>
                  <select
                    value={categoryId}
                    onChange={(e) => setCategoryId(e.target.value)}
                  >
                    <option value="category">Select category</option>
                    {categories?.map((item) => (
                      <option value={item._id}>{item.name}</option>
                    ))}
                  </select>
                </div>

                {success && (
                  <div style={{ color: "green", paddingBottom: ".1rem" }}>
                    <p>{success}</p>
                  </div>
                )}

                {error && (
                  <div style={{ color: "red", paddingBottom: ".1rem" }}>
                    {Object.keys(error).map((keyName, i) => (
                      <p>{error[keyName]}</p>
                    ))}
                  </div>
                )}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    color: "green",
                  }}
                >
                  <div></div>
                  <div
                    type="button"
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                  >
                    Add category
                  </div>
                </div>

                <button
                  className="formbuts"
                  type="submit"
                  disabled={!getIsFormValid()}
                >
                  Post
                </button>
              </fieldset>
            </div>
          </form>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Add category
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="Field">
                <label className="labelsc">Category name</label>
                <input
                  value={newCat}
                  onChange={(e) => {
                    setNewCat(e.target.value);
                  }}
                  placeholder="Enter category name"
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                id="closeModal"
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                onClick={() => AddCategory()}
                type="button"
                className="btn btn-success"
                disabled={isAdding}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Post;
