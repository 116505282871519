import React from "react";
import axios from "axios";
import { API } from "../config";
class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: "",
      email: "",
      location: "",
      subject: "",
      message: "",
      isLoading: false,
      error: {},
    };
  }

  render() {
    return (
      <div className="container-fluid back1" id="contact">
        <div>
          <h2 className="h2 us">
            <b>CONTACT US</b>
          </h2>
          <div className="line1 zoom-out"></div>
          <p className="para act">
            Info-Line(+237) 652-152-289 <br></br> Email:grabit.sale1@gmail.com{" "}
          </p>
        </div>

        <div className="col-sm-4 margina">
          <div className="App4 mx-auto">
            <form
              id="contact-form"
              onSubmit={this.handleSubmit.bind(this)}
              method=""
            >
              <div className=" formbs">
                <fieldset>
                  <h2 className="h2 help">We Are Here To Help You</h2>

                  <div className="Field">
                    <input
                      type="text"
                      placeholder="Full Names"
                      value={this.state.fullName}
                      onChange={this.onNameChange.bind(this)}
                      className="contact"
                      required
                    />
                  </div>

                  <div className="Field">
                    <input
                      type="email"
                      placeholder="Email Address"
                      aria-describedby="emailHelp"
                      value={this.state.email}
                      onChange={this.onEmailChange.bind(this)}
                      className="contact"
                      required
                    />
                  </div>

                  <div className="Field">
                    <input
                      type="text"
                      placeholder="Location"
                      value={this.state.location}
                      onChange={this.onLocationChange.bind(this)}
                      className="contact"
                      required
                    />
                  </div>
                  <div className="Field">
                    <input
                      type="text"
                      placeholder="Subject"
                      value={this.state.subject}
                      onChange={this.onSubjectChange.bind(this)}
                      className="contact"
                      required
                    />
                  </div>
                  <div className="Field">
                    <textarea
                      placeholder="Message"
                      className="textar contact"
                      rows="4"
                      value={this.state.message}
                      onChange={this.onMessageChange.bind(this)}
                      required
                    />
                  </div>

                  <button
                    disabled={this.state.isLoading}
                    className="text-center formbutss"
                    type="submit"
                  >
                    Send
                  </button>

                  {this.state.error && (
                    <div style={{ color: "red", paddingBottom: ".1rem" }}>
                      {Object.keys(this.state.error).map((keyName, i) => (
                        <p>{this.state.error[keyName]}</p>
                      ))}
                    </div>
                  )}
                </fieldset>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
  onNameChange(event) {
    this.setState({ fullName: event.target.value });
  }
  onEmailChange(event) {
    this.setState({ email: event.target.value });
  }
  onLocationChange(event) {
    this.setState({ location: event.target.value });
  }
  onSubjectChange(event) {
    this.setState({ subject: event.target.value });
  }
  onMessageChange(event) {
    this.setState({ message: event.target.value });
  }
  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ isLoading: true });

    try {
      const { fullName, email, location, subject, message } = this.state;
      const result = await axios.post(`${API}/email/send`, {
        fullName,
        email,
        location,
        subject,
        message,
      });
      console.log("====================", result);
      if (result.status === 201) {
        alert("Your email has been sent. We will reach out to you soon!");
        window.location.href = "/";
      } else {
        this.setState({ error: result.error.response.data.errors });
      }
    } catch (error) {
      console.log("====================", error);
      this.setState({ error: error.response.data.errors });
    }

    this.setState({ isLoading: false });
    console.log(this.state);
  }
}

export default Contact;
