import React, { useState, useEffect } from "react";
import "../App.css";
import Footer from "./footer";
import Navbar from ".";
import axios from "axios";
import { API } from "../config";
import { Link } from "react-router-dom";
const Payments = () => {
  const [order, setOrder] = useState({});
  const [info, setInfo] = useState({
    totalAmountToWithdraw: 0,
    totalAmountWithdrawn: 0,
    totalNumberOfOrders: 0,
    undeliveredOrders: 0,
    deliveredOrders: 0,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [store, setStore] = useState({});
  const [success, setSuccess] = useState("");
  let user = localStorage.getItem("user");
  if (user) {
    user = JSON.parse(user);
  }
  useEffect(() => {
    const listOrders = async () => {
      try {
        if (!user || user.role !== "seller") {
          window.location.href = "/";
        }
        const result = await axios.get(`${API}/store/user/${user._id}`);
        setStore(result.data.store[0]);
        const orders = await axios.post(
          `${API}/order/order-details`,
          {
            userId: user._id,
            token: user.originToken,
            storeId: result.data.store[0]._id,
          },
          {
            headers: { Authorization: `Bearer ${user.token}` },
          }
        );
        setOrder(orders.data.details);

        const neworders = orders.data.details;

        setInfo({
          totalAmountToWithdraw: neworders?.totalAmountToWithdraw,
          totalAmountWithdrawn: neworders?.totalAmountWithdrawn,
          totalNumberOfOrders: neworders?.totalNumberOfOrders,
          undeliveredOrders: neworders?.undeliveredOrders?.length,
          deliveredOrders: neworders?.deliveredOrders?.length,
        });
      } catch (err) {
        console.log("cart", err);
      }
    };
    listOrders();
  }, []);

  const initiateWidthdrawal = async () => {
    setIsSubmitting(true);
    try {
      const withdrawal = await axios.post(
        `${API}/payment/init-withdrawal`,
        {
          userId: user._id,
          token: user.originToken,
          storeId: store._id,
        },
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
    } catch (error) {
      console.log("error", error);
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid sign">
        <div className="cartco">
          <div className="card ">
            <div className="row">
              <div className="col-md-8 cart">
                <div className="title">
                  <div className="row">
                    <div className="col">
                      <h4>
                        <b>Payment datails</b>
                      </h4>
                    </div>
                  </div>
                </div>

                <div className="row border-top border-bottom">
                  <div className="row main align-items-center">
                    <div className="col">
                      <div className="row text-muted">
                        Total number of Orders
                      </div>
                    </div>
                    <div className="col">{info?.totalNumberOfOrders}</div>
                  </div>
                </div>

                <div className="row border-top border-bottom">
                  <div className="row main align-items-center">
                    <div className="col">
                      <div className="row text-muted">
                        Total number of undelivered Orders
                      </div>
                    </div>
                    <div className="col">{info?.undeliveredOrders}</div>
                  </div>
                </div>

                <div className="row border-top border-bottom">
                  <div className="row main align-items-center">
                    <div className="col">
                      <div className="row text-muted">
                        Total number of delivered Orders
                      </div>
                    </div>
                    <div className="col">{info?.deliveredOrders}</div>
                  </div>
                </div>

                <div className="row border-top border-bottom">
                  {/* <div className="row main align-items-center">
                    <div className="col">
                      <div className="row text-success">
                        Note: Withdrawals are done once a week that is on
                        Saturdays
                      </div>
                    </div>
                  </div> */}
                  <div className="row main align-items-center">
                    <div className="row">
                      <Link to="/orders">
                        <button type="button" className="btn btn-success text">
                          View store order list
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 summary">
                <div style={{ marginBottom: "20px" }}>
                  <h5>
                    <b>Summary</b>
                  </h5>
                </div>

                <div className="row stylep">
                  <div className="col">Account Balance</div>
                  <div className="col text-right">
                    XAF {info?.totalAmountToWithdraw}
                  </div>
                </div>
                <div className="row stylep">
                  <div className="col">Total Withdrawals</div>
                  <div className="col text-right">
                    XAF {info?.totalAmountWithdrawn}
                  </div>
                </div>

                <button
                  className="btn btn-success checki"
                  disabled={info?.totalAmountToWithdraw <= 0 || isSubmitting}
                  onClick={() => initiateWidthdrawal()}
                >
                  Withdraw Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Payments;
