import React from "react";
import '../App.css';
import MyImage from '../images/aboutg.jfif';
import { Link } from "react-router-dom";

const Mission = () => {

    return (

<div className="container-fluid topm back">
    <div className="row">
        <div className="col-sm-5">
            <img className="imgwa zoom" src={MyImage}></img>
        </div>
        <div className="col-sm-7 padd">
            <h2 className="h2 ith"><b>GrabIT</b></h2>
            <div className="line2 zoom-out"></div>

            <p className="ps">GrabIT is an all-in-one online shop that gives you 
the local views about products available for purchase in shops, 
markets and trends to help you figure out
 exactly what, where, and when to buy or  sell. You can 
also find products, view prices of products, Location. 

We work with different Shop Owners, Individual Businesses
delivery agencies, to give them more visibility
 and enable them showcase their services. Our goal is to
 REDUCE THE STRESS involved in the process of searching
 of searching and buying of products. So, if you are looking 
for a product for purchase/sale, then you are in the right place.
<br></br>
<br></br>

<h4 className="fots"><b>Media Handles:</b></h4>
<Link to="https://www.facebook.com/profile.php?id=61558529822396&mibextid=kFxxJD"><button className=" icons fa fa-facebook-official"></button></Link>
<Link to="https://x.com/Grabitshopping?s=09"><button className="icons  fa fa-close"></button></Link>
<Link to= "https://www.instagram.com/grabitsale1?igsh=NzU1c3M1bzZoNXRl"><button className="icons fa fa-instagram"></button></Link>
<Link to= "https://www.tiktok.com/@grabit92?_t=8lbqfHKZVob&_r=1"><button className="icons	fa fa-tumblr-square"></button></Link>
</p>

        </div>

    </div>

</div>

)
}

export default Mission;
