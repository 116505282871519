import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import userService from "../redux-store/feature/user/userService";
import axios from "axios";
import { API } from "../config";

function LoginForm() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState("password");
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    // Validate email
    if (!formData.email) {
      newErrors.email = "Email is required";
      isValid = false;
    }

    // Validate password
    if (!formData.password) {
      newErrors.password = "Password is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setIsSubmitting(true);
      setError("");
      try {
        const result = await axios.post(`${API}/user/signin`, {
          email: formData.email,
          password: formData.password,
        });
        console.log("user=====================", result);
        if (result.status === 200) {
          localStorage.removeItem("user");
          localStorage.setItem("user", JSON.stringify(result.data.user));
          console.log("currentUser", result.data.user);
          // let newU = localStorage.getItem("user");
          // console.log(JSON.parse(newU));
          window.location.href = "/";
        } else {
          setError("Email or Password is incurrect");
        }
      } catch (error) {
        console.log("user=====================", error);
        if (error.code === "ERR_NETWORK") {
          setError("Network error");
        } else {
          const err = Object.keys(error.response.data.errors);
          if (err.length !== 0) {
            setError("Email or Password is incurrect");
          } else {
            setError("Account does not exist");
          }
        }
      }
      setIsSubmitting(false);

      // setSubmitted(true); // Set a submitted flag
    } else {
      // Form is not valid, display error messages
    }
  };

  const isFormValid = Object.keys(errors).length === 0 && !isSubmitting;

  return (
    <div className="container-fluid ing sign">
      <div className="App1 mx-auto">
        {submitted ? (
          <div className="success-message ground">Login successful!</div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="formb">
              <h2 className="forma h2">
                <b>Login</b>
              </h2>
              <div className="line zoom-out"></div>

              {error && (
                <div
                  style={{
                    color: "red",
                    marginBottom: "-2rem",
                    marginTop: "rem",
                    textAlign: "center",
                  }}
                >
                  <p>{error}</p>
                </div>
              )}

              <fieldset>
                <div className="Field">
                  <label>
                    Email<sup>*</sup>{" "}
                  </label>
                  <input
                    type="text"
                    name="email"
                    placeholder="Email address"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                  {errors.email && <div className="error">{errors.email}</div>}
                </div>

                <div className="Field" style={{ position: "relative" }}>
                  <label>
                    Password<sup>*</sup>{" "}
                  </label>
                  <input
                    type={passwordVisibility}
                    name="password"
                    placeholder="password"
                    value={formData.password}
                    onChange={handleInputChange}
                  />
                  {errors.password && (
                    <div className="error">{errors.password}</div>
                  )}
                  <div
                    style={{
                      position: "absolute",
                      right: "1rem",
                      color: "black",
                      top: "2.25rem",
                      border: 0,
                      background: "transparent",
                    }}
                    onClick={() =>
                      passwordVisibility === "text"
                        ? setPasswordVisibility("password")
                        : setPasswordVisibility("text")
                    }
                    className="visibility"
                  >
                    {passwordVisibility === "text" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 256 256"
                      >
                        <path
                          fill="currentColor"
                          d="M53.92 34.62a8 8 0 1 0-11.84 10.76l19.24 21.17C25 88.84 9.38 123.2 8.69 124.76a8 8 0 0 0 0 6.5c.35.79 8.82 19.57 27.65 38.4C61.43 194.74 93.12 208 128 208a127.1 127.1 0 0 0 52.07-10.83l22 24.21a8 8 0 1 0 11.84-10.76Zm47.33 75.84l41.67 45.85a32 32 0 0 1-41.67-45.85M128 192c-30.78 0-57.67-11.19-79.93-33.25A133.2 133.2 0 0 1 25 128c4.69-8.79 19.66-33.39 47.35-49.38l18 19.75a48 48 0 0 0 63.66 70l14.73 16.2A112 112 0 0 1 128 192m6-95.43a8 8 0 0 1 3-15.72a48.16 48.16 0 0 1 38.77 42.64a8 8 0 0 1-7.22 8.71a6 6 0 0 1-.75 0a8 8 0 0 1-8-7.26A32.09 32.09 0 0 0 134 96.57m113.28 34.69c-.42.94-10.55 23.37-33.36 43.8a8 8 0 1 1-10.67-11.92a132.8 132.8 0 0 0 27.8-35.14a133.2 133.2 0 0 0-23.12-30.77C185.67 75.19 158.78 64 128 64a118.4 118.4 0 0 0-19.36 1.57A8 8 0 1 1 106 49.79A134 134 0 0 1 128 48c34.88 0 66.57 13.26 91.66 38.35c18.83 18.83 27.3 37.62 27.65 38.41a8 8 0 0 1 0 6.5Z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 256 256"
                      >
                        <path
                          fill="currentColor"
                          d="M247.31 124.76c-.35-.79-8.82-19.58-27.65-38.41C194.57 61.26 162.88 48 128 48S61.43 61.26 36.34 86.35C17.51 105.18 9 124 8.69 124.76a8 8 0 0 0 0 6.5c.35.79 8.82 19.57 27.65 38.4C61.43 194.74 93.12 208 128 208s66.57-13.26 91.66-38.34c18.83-18.83 27.3-37.61 27.65-38.4a8 8 0 0 0 0-6.5M128 192c-30.78 0-57.67-11.19-79.93-33.25A133.5 133.5 0 0 1 25 128a133.3 133.3 0 0 1 23.07-30.75C70.33 75.19 97.22 64 128 64s57.67 11.19 79.93 33.25A133.5 133.5 0 0 1 231.05 128c-7.21 13.46-38.62 64-103.05 64m0-112a48 48 0 1 0 48 48a48.05 48.05 0 0 0-48-48m0 80a32 32 0 1 1 32-32a32 32 0 0 1-32 32"
                        />
                      </svg>
                    )}
                  </div>
                </div>

                <button
                  type="submit"
                  disabled={!isFormValid}
                  className="formbuts"
                >
                  Login
                </button>
              </fieldset>

              <div className="linksl">
                <a href="./signup" className="loginalt">
                  Create An Account
                </a>
                <a href="./forgot" className="loginat">
                  Forgot Password?
                </a>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default LoginForm;
